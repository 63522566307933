/* eslint-disable */
import { constantRouterMap } from "@/router";
import { asyncRouterMap } from "@/router/modules/router.js";
import { appInfos } from "@/appInfos";

const formatterRoutes = (menu, appPrefix, appName) => {
  const accessedRoutes = [];
  menu.map(item => {
    // if (!item.noDisplay) {
    const defaultOpt = {
      path: appPrefix + item.path,
      meta: {
        menuName: item.menuName || item.title,
        icon: item.icon,
        appName,
        appPrefix
      },
      hidden: item.hidden,
      noDisplay: item.noDisplay
    };
    if (item.children && Object.prototype.toString.call(item.children) === "[object Array]" && item.children.length) {
      accessedRoutes.push({
        ...defaultOpt,
        alwaysShow: true,
        children: formatterRoutes(item.children, appPrefix, appName)
      });
    } else {
      accessedRoutes.push({ ...defaultOpt });
    }
    // }
  });
  return accessedRoutes;
};

/* *
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(menuList, route) {
  if (menuList && menuList.length) {
    let menuInfo = menuList.find(item => {
      let result = !route.meta || item.m === route.meta.menu_Id;
      return result;
    });
    if (route.meta && menuInfo && menuInfo.d) {
      route.meta.menuName = menuInfo.d; // 启用别名
    }
    return !!menuInfo;
  } else {
    return false;
  }
}

/* *
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function findNode(orginMenuList, menu_Id) {
  for (let index = 0; index < orginMenuList.length; index++) {
    let route = orginMenuList[index];
    if (route.meta && route.meta.menu_Id === menu_Id) {
      return route;
    }
    if (route.children) {
      let existRoute = findNode(route.children, menu_Id);
      if (existRoute) return existRoute;
    }
  }
  return false;
}

/* *
 * 调整菜单
 * @param routes asyncRouterMap
 * @param menuList
 */
function adjustAsyncRouter(orginMenuList, customMenuList) {
  customMenuList.forEach(route => {
    if (route.meta) {
      let _route = findNode(orginMenuList, route.meta.menu_Id);
      if (_route) {
        route.component = _route.component;
        // route.meta.menuName += "1";
      }
    }
    if (route.children) {
      adjustAsyncRouter(orginMenuList, route.children);
    }
  });
}

/* *
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param menuList
 */
function filterAsyncRouter(routes, menuList) {
  const res = [];

  routes.forEach(route => {
    if (hasPermission(menuList, route)) {
      const tmp = { ...route };
      if (tmp.children) {
        tmp.children = filterAsyncRouter(tmp.children, menuList);
      }
      res.push(tmp);
    }
  });

  return res;
}
const routers = constantRouterMap;
const permission = {
  state: {
    routers: routers,
    addRouters: [],
    showSubApp: false,
    // isShow404: false,
    subApp: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers;
      let allRouters = constantRouterMap.concat(routers);
      state.routers = allRouters;
    },
    UPDATE_ROUTERS: (state, routers) => {
      state.routers = constantRouterMap;
      state.addRouters = [];
    },
    SET_SHOW_TRUE: state => {
      state.showSubApp = true;
    },
    // SET_SHOW_404: (state, flag) => {
    // state.isShow404 = flag
    // },
    SET_SUB_APP: (state, subapp) => {
      state.subApp = [...subapp];
    }
  },
  actions: {
    GenerateRoutes({ commit }, { menuList, customMenuList }) {
      return new Promise(resolve => {
        let accessedRouters = asyncRouterMap;
        // 自定义菜单
        if (customMenuList) {
          customMenuList = eval(customMenuList);
          if (Array.isArray(customMenuList) && customMenuList.length) {
            adjustAsyncRouter(asyncRouterMap, customMenuList);
            accessedRouters = customMenuList;
          }
        }
        accessedRouters = filterAsyncRouter(accessedRouters, menuList);

        commit("SET_ROUTERS", accessedRouters);
        resolve();
      });
    },
    generateRoutes({ commit }, menus) {
      return new Promise(resolve => {
        // 动态加入 路由  等真实接口 要排除一些权限路由不显示 以及一些 不显示在 左侧栏的 路由
        // asyncRouterMap
        // 先取第一个
        let accessedRoutes = [...menus];
        accessedRoutes.push(...asyncRouterMap);
        // 检测 appInfos和accessedRoutes 是否一致 抛出异常
        if (!accessedRoutes.length) {
          throw "请确保菜单管理中配置了子应用的菜单信息";
        }
        const path = accessedRoutes[0].path.split("/")[1];

        // let flag = appInfos.findIndex((item) => item.name === path);
        // if (flag === -1) {
        //   throw `请确保appInfos里的name字段和菜单管理中配置的应用前缀一致`;
        // }

        // 清除以前的动态路由
        const addRouters = [...this.getters.addRouters];
        if (addRouters && addRouters.length) {
          commit("UPDATE_ROUTERS", addRouters);
        }

        // 自定义菜单
        let customMenuList = this.getters.customMenuList;
        if (customMenuList) {
          try {
            customMenuList = eval(customMenuList);
            if (Array.isArray(customMenuList) && customMenuList.length) {
              adjustAsyncRouter(asyncRouterMap, customMenuList);
              accessedRoutes = customMenuList;
            }
          } catch (error) {
            console.log("自定义菜单错误：" + error.message);
          }
        }
        accessedRoutes = filterAsyncRouter(accessedRoutes, this.getters.menuList);

        commit("SET_ROUTERS", accessedRoutes);
        resolve();
      });
    },
    generateSubApp({ commit }, menus) {
      return new Promise(resolve => {
        const newMenus = [];
        menus.map(item => {
          // 对子应用做权限判断  。。。。。
          // if (!item.noDisplay) {  // 如果有权限  则添加
          newMenus.push({ ...item, appMenus: formatterRoutes(item.appMenus, item.appPrefix, item.appName) });
          // }
        });
        commit("SET_SUB_APP", newMenus);
        resolve();
      });
    },
    setShowTrue({ commit }) {
      commit("SET_SHOW_TRUE");
    }
  }
};

export default permission;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "window-tips-box",
    },
    [
      _c(
        "div",
        {
          staticClass: "el-alert is-center is-dark",
          class: { ["el-alert--" + _vm.tipsConfig.type]: true },
        },
        [
          _c("i", {
            staticClass: "el-alert__icon",
            class: { ["el-icon-" + _vm.tipsConfig.type]: true },
          }),
          _c("div", { staticClass: "el-alert__content" }, [
            _c("span", {
              staticClass: "el-alert__title",
              domProps: { innerHTML: _vm._s(_vm.tipsConfig.message) },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/* eslint-disable */
import Vue from "vue";
import VueI18n from "vue-i18n";
import elementEnLocale from "element-ui/lib/locale/lang/en"; //  element-ui lang
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"; //  element-ui lang
import elementzhTWLocale from "element-ui/lib/locale/lang/zh-TW"; //  element-ui lang
import enLocale from "./en";
import zhLocale from "./zh";
import zh_TW from "./zh_TW";
import locale from "element-ui/lib/locale";

Vue.use(VueI18n);

const messages = {
  zh: {
    ...zhLocale,
    ...elementZhLocale
  },
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh_TW: {
    ...zh_TW,
    ...elementzhTWLocale
  }
};

const i18n = new VueI18n({
  //  set locale
  //  options: en | zh | es
  locale: localStorage.getItem("language") || "zh",
  fallbackLocale: "zh",
  //  set locale messages
  messages
});
locale.i18n((key, value) => i18n.t(key, value)); //为了实现element插件的多语言切换

export default i18n;

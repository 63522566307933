var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "theme-select" }, [
    _c(
      "ul",
      _vm._l(_vm.themeList, function (item) {
        return _c(
          "li",
          {
            key: item.name,
            class: { actived: item.name === _vm.theme, hidden: item.hidden },
            on: {
              click: function ($event) {
                return _vm.changeTheme(
                  item.name,
                  item.label,
                  item.hidden || false
                )
              },
            },
          },
          [
            _c("img", { attrs: { src: item.src } }),
            _c("span", [_vm._v(_vm._s(item.label))]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
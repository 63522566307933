var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    { staticClass: "international", attrs: { trigger: "click" } },
    [
      _c("div", { on: { click: _vm.clockIn } }, [
        _c("i", { staticClass: "el-icon-s-claim international-icon" }),
        _vm._v(" 打卡 "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <div>
      <svg-icon class-name="international-icon" icon-class="language" /> {{ lang[language] }}
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item :disabled="language==='zh'" command="zh">中文</el-dropdown-item>
      <el-dropdown-item :disabled="language==='en'" command="en">English</el-dropdown-item>
      <el-dropdown-item :disabled="language==='zh_TW'" command="zh_TW">繁体中文</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  data () {
    return {
      lang: {
        zh: "简体中文",
        en: "English",
        zh_TW: "繁体中文"
      }
    };
  },
  computed: {
    language () {
      return this.$store.getters.language;
    }
  },
  mounted () {
    // 直接上架
    const language = localStorage["language"];
    if (language) {
      this.$store.dispatch("setLanguage", language);
    }
  },
  methods: {
    handleSetLanguage (lang) {
      localStorage["language"] = lang;
      this.$i18n.locale = lang;
      this.$store.dispatch("setLanguage", lang);
      this.$message({
        message: "Switch Language Success",
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
.international-icon {
  font-size: 20px;
  cursor: pointer;
  vertical-align: -5px !important;
}
</style>


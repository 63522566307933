var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "toast-box" }, [
    _c("i", { staticClass: "icon", class: _vm.icon }),
    _c("span", [_vm._v(_vm._s(_vm.message))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
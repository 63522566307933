var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-settings" }, [
    _c(
      "div",
      [
        _c("h3", [_vm._v("主题设置")]),
        _c(
          "div",
          { staticClass: "theme-picker flex between" },
          [_vm._m(0), _c("theme-picker")],
          1
        ),
        _c(
          "el-collapse",
          {
            attrs: { accordion: "" },
            model: {
              value: _vm.collapseNames,
              callback: function ($$v) {
                _vm.collapseNames = $$v
              },
              expression: "collapseNames",
            },
          },
          [
            _c(
              "el-collapse-item",
              { attrs: { title: "界面皮肤", name: "theme" } },
              [_c("div", { staticClass: "change-theme" }, [_c("theme")], 1)]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", [
      _vm._v(" 主题色 "),
      _c("sup", { staticClass: "act-color" }, [_vm._v("beta")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
/* eslint-disable */
import axios from "axios";
import { Loading, Notification, Message } from "element-ui";
import md5 from "@/utils/md5.min.js";
import store from "@/store";
import router from "../router/index.js";
import moment from "moment";
import { userInfoCookie, menuListCookie } from "@/utils/auth";

export default {
  domain: process.env.NODE_ENV === "production" ? window.domain : "http://127.0.0.1:7001", // API接口地址
  templateDomain: null, // 模板下载地址
  ureportDomain: "http://39.99.242.248:8089", // 报表设计器
  ossDomain: null, // 国内oss
  uploadConf: null, // 阿里云oss配置
  headers: {},
  ajax: async function (url, params, callback, loadingTarget) {
    if (typeof callback !== "function") {
      loadingTarget = callback;
    }

    // axios.defaults.timeout = 1000 * 60 * 2;
    let getToken = async () => {
      let tokenKey = "$tokenInfo" + store.getters.userType;
      let tokenInfo = JSON.parse(localStorage.getItem(tokenKey) || "{}");
      if (!tokenInfo || !tokenInfo.signToken || Date.parse(tokenInfo.expireTime) - new Date().getTime() <= 0) {
        // 超时退出系统
        let userInfo = this.getUserInfo();
        if (tokenInfo.expireTime && userInfo.global_loginTimeout) {
          let global_loginTimeout = userInfo.global_loginTimeout * 60 * 1000; // 转换为毫秒
          let spanTime = new Date().getTime() - Date.parse(tokenInfo.expireTime);
          if (global_loginTimeout < spanTime) {
            localStorage.removeItem(tokenKey);
            store.dispatch("logOut").then(() => {
              Message.error("系统登录超时，请重新登录");
              let redirect = router ? router.currentRoute.path : "/";
              location.href = `/portal-login?redirect=${redirect}`;
            });
            return;
          }
        }
        let url = "/api/auth/getToken";
        let guid = this.getUserGuid();
        let _params = {
          guid: guid
        };

        let baseURL = this.domain;
        //  create an axios instance
        let request = axios.create({
          baseURL: baseURL, //  api 的 base_url
          timeout: 1000 * 60 * 20 // request timeout 20min
        });
        request.interceptors.request.use(
          config => {
            this.loading(loadingTarget);
            return config;
          },
          error => {
            // 关闭loading
            this.loaded(loadingTarget);

            return Promise.reject(error);
          }
        );
        let response = await request({
          url: url,
          method: "get",
          params: _params
        });
        // 关闭loading
        this.loaded(loadingTarget);
        let res = this.objectToCase(response.data);
        tokenInfo = res.data;
        if (tokenInfo) {
          localStorage.setItem(tokenKey, JSON.stringify(tokenInfo));
        } else {
          localStorage.removeItem(tokenKey);
        }
        return tokenInfo;
      } else {
        return tokenInfo;
      }
    };
    let getData = async tokenInfo => {
      let tokenKey = "$tokenInfo" + store.getters.userType;
      let baseURL = this.domain;
      //  create an axios instance
      let request = axios.create({
        baseURL: baseURL, //  api 的 base_url
        timeout: 1000 * 60 * 20 // request timeout 20min
      });
      request.interceptors.request.use(
        config => {
          // 前面信息
          let timestamp = new Date().valueOf();
          let nonce = Math.random().toString(36).substring(2);
          let guid = this.getUserGuid();
          if (!tokenInfo || !tokenInfo.signToken) {
            Message.error({
              title: "错误",
              message: "未获取到签名token，无法执行操作！"
            });
            // 关闭loading
            this.loaded(loadingTarget);
            return;
          }
          let userInfo = this.getUserInfo();
          let accessToken = userInfo.accessToken;

          let signature = this.getSignature(timestamp, nonce, tokenInfo, params);
          let headers = Object.assign(
            {
              timestamp: timestamp,
              nonce: nonce,
              guid: guid,
              accessToken: accessToken,
              signature: signature
            },
            this.headers
          );
          // 清空header，防止污染后面请求
          this.headers = {};

          config.headers = headers;
          this.loading(loadingTarget);

          return config;
        },
        error => {
          // 关闭loading
          this.loaded(loadingTarget);

          return Promise.reject(error);
        }
      );
      let response = await request({
        url: url,
        method: "post",
        data: params
      });
      let status = response.data.statusCode;
      if ([401, 403, 406, 407].indexOf(status) >= 0) {
        let redirect = router ? router.currentRoute.path : "/";
        localStorage.removeItem(tokenKey);
        store.dispatch("logOut").then(() => {
          Message.error("系统未登录");
          location.href = `/portal-login?redirect=${redirect}`;
        });
        // 关闭loading
        this.loaded(loadingTarget);
        return;
      }
      // 关闭loading
      this.loaded(loadingTarget);
      let data = response.data;
      if (typeof callback === "function") {
        callback(data);
      }
      return data;
    };
    let tokenInfo = await getToken();
    let data = await getData(tokenInfo);
    return data;
  },
  /**
   * 将首字母小写
   * @param {String} str 需要转换的字符串
   * @param {String} type 转换类型，Upper或者Lower
   */
  caseStyle(str, type = "lower") {
    if (!str || str.length < 2) return str;
    if (type === "upper") {
      str = str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      str = str.charAt(0).toLowerCase() + str.slice(1);
    }
    return str;
  },
  /**
   * 将表名转换为文件名
   * @param {String} tableName 表名
   */
  tableNameToFileName(tableName) {
    if (!tableName) return tableName;

    let tableView = "";
    let names = tableName.split("_");
    // 文件名
    for (let i = 0; i < names.length; i++) {
      if (i == 0) {
        tableView += this.caseStyle(names[0]);
      } else {
        tableView += this.caseStyle(names[i], "upper");
      }
    }
    return tableView;
  },
  /**
   * 将表名转换为类名
   * @param {String} tableName 表名
   */
  tableNameToClassName(tableName) {
    if (!tableName) return tableName;

    let tableView = "";
    let names = tableName.split("_");
    // 文件名
    for (let i = 0; i < names.length; i++) {
      if (i == 0) {
        let name = this.caseStyle(names[0], "upper");
        if (name.substring(0, 1).toUpperCase() === "V") {
          name = "v" + name.substring(1);
        }
        tableView += name;
        let keys = ["ERP", "OA", "API", "CIQ", "TMS", "eBay"];
        for (let key of keys) {
          let regS = new RegExp(key, "gi");
          tableView = tableView.replace(regS, key);
        }
      } else {
        tableView += this.caseStyle(names[i], "upper");
      }
    }
    return tableView;
  },
  /**
   * 将对象属性首字母转为大小写，默认转换为小写
   * @param {Object} obj 需要转换的对象
   * @param {String} type 转换类型，Upper或者Lower
   */
  objectToCase(obj, type = "lower") {
    if (!obj) return obj;

    let data = {};
    // 数据转换
    Object.keys(obj).forEach(key => {
      let val = obj[key];
      let _key = this.caseStyle(key, type);
      if (Array.isArray(val)) {
        data[_key] = this.arrayToCase(val);
      } else if (Object.prototype.toString.call(val) === "[object Object]") {
        data[_key] = this.objectToCase(val);
      } else {
        data[_key] = val;
      }
    });
    return data;
  },
  /**
   * 将数组属性首字母转为大小写，默认转换为小写
   * @param {Array} obj 需要转换的数组
   * @param {String} type 转换类型，Upper或者Lower
   */
  arrayToCase(obj, type = "lower") {
    if (!obj) return obj;

    if (!Array.isArray(obj)) {
      return obj;
    }

    let _arrList = [];
    for (let item of obj) {
      if (typeof item === "object") {
        item = this.objectToCase(item, type);
        _arrList.push(item);
      } else {
        _arrList.push(item);
      }
    }
    return _arrList;
  },
  // 显示ajax返回消息
  showMsg(res) {
    if (res.result && res.msg && res.msg.indexOf("color='red'") < 0) {
      if (res.msg)
        Message.success({
          dangerouslyUseHTMLString: true,
          message: res.msg
        });
    } else {
      if (res.msg) {
        Message.error({
          dangerouslyUseHTMLString: true,
          message: res.msg
        });
      }
    }
  },
  // 获取登录用户信息
  getUserInfo() {
    let userInfo = userInfoCookie.getUserInfo();
    if (!userInfo) userInfo = {};

    return userInfo;
  },
  // 获取登录用户信息
  getTokenInfo() {
    let tokenKey = "$tokenInfo" + store.getters.userType;
    let tokenInfo = JSON.parse(localStorage.getItem(tokenKey) || "{}");

    return tokenInfo;
  },
  // 获得对话弹出顶部高度
  getDialogTop: function () {
    let screenHeight = document.documentElement.clientHeight || document.body.clientHeight;
    return screenHeight > 900 ? "10vh" : "3vh";
  },
  loadingOject: null,
  loading: function (target, bgColor = "rgba(255, 255, 255, 0.5)") {
    if (target === false || !target) return;
    let opts = {
      text: "数据加载中",
      spinner: "el-icon-loading",
      background: bgColor,
      lock: true
    };
    if (typeof target === "object") {
      opts.target = target.$el || target;
      if (!this.loadingOject) {
        this.loadingOject = Loading.service(opts);
        this.loadingOject.loadingCount = 1;
      } else {
        this.loadingOject.loadingCount += 1;
      }
    } else {
      if (this.loadingOject) {
        this.loadingOject.loadingCount += 1;
      } else {
        opts.fullscreen = true;
        this.loadingOject = Loading.service(opts);
        this.loadingOject.loadingCount = 1;
      }
    }
  },
  loaded: function (target, bgColor = "rgba(255, 255, 255, 0.5)") {
    if (target === false || !target) return;
    let opts = {
      text: "数据加载中",
      spinner: "el-icon-loading",
      background: bgColor
    };
    if (this.loadingOject) {
      this.loadingOject.loadingCount -= 1;
      if (this.loadingOject.loadingCount === 0) {
        this.loadingOject.close();
        this.loadingOject = null;
      }
    }
  },
  // 自定义函数, 获得GUID值
  getGUID: function () {
    function GUID() {
      this.date = new Date(); /* 判断是否初始化过，如果初始化过以下代码，则以下代码将不再执行，实际中只执行一次 */
      if (typeof this.newGUID !== "function") {
        /* 生成GUID码 */
        GUID.prototype.newGUID = function () {
          this.date = new Date();
          let guidStr = "";
          let sexadecimalDate = this.hexadecimal(this.getGUIDDate(), 16);
          let sexadecimalTime = this.hexadecimal(this.getGUIDTime(), 16);
          for (let i = 0; i < 9; i++) {
            guidStr += Math.floor(Math.random() * 16).toString(16);
          }
          guidStr += sexadecimalDate;
          guidStr += sexadecimalTime;
          while (guidStr.length < 32) {
            guidStr += Math.floor(Math.random() * 16).toString(16);
          }
          return this.formatGUID(guidStr);
        };
        /* * 功能：获取当前日期的GUID格式，即8位数的日期：19700101 * 返回值：返回GUID日期格式的字条串 */
        GUID.prototype.getGUIDDate = function () {
          return this.date.getFullYear() + this.addZero(this.date.getMonth() + 1) + this.addZero(this.date.getDay());
        };
        /* * 功能：获取当前时间的GUID格式，即8位数的时间，包括毫秒，毫秒为2位数：12300933 * 返回值：返回GUID日期格式的字条串 */
        GUID.prototype.getGUIDTime = function () {
          return this.addZero(this.date.getHours()) + this.addZero(this.date.getMinutes()) + this.addZero(this.date.getSeconds()) + this.addZero(parseInt(this.date.getMilliseconds() / 10));
        };
        /* * 功能: 为一位数的正整数前面添加0，如果是可以转成非NaN数字的字符串也可以实现 * 参数: 参数表示准备再前面添加0的数字或可以转换成数字的字符串 * 返回值: 如果符合条件，返回添加0后的字条串类型，否则返回自身的字符串 */
        GUID.prototype.addZero = function (num) {
          if (Number(num).toString() !== "NaN" && num >= 0 && num < 10) {
            return "0" + Math.floor(num);
          } else {
            return num.toString();
          }
        };
        /* * 功能：将y进制的数值，转换为x进制的数值 * 参数：第1个参数表示欲转换的数值；第2个参数表示欲转换的进制；第3个参数可选，表示当前的进制数，如不写则为10 * 返回值：返回转换后的字符串 */
        GUID.prototype.hexadecimal = function (num, x, y) {
          if (y !== undefined) {
            return parseInt(num.toString(), y).toString(x);
          } else {
            return parseInt(num.toString()).toString(x);
          }
        };
        /* * 功能：格式化32位的字符串为GUID模式的字符串 * 参数：第1个参数表示32位的字符串 * 返回值：标准GUID格式的字符串 */
        GUID.prototype.formatGUID = function (guidStr) {
          let str1 = guidStr.slice(0, 8) + "-",
            str2 = guidStr.slice(8, 12) + "-",
            str3 = guidStr.slice(12, 16) + "-",
            str4 = guidStr.slice(16, 20) + "-",
            str5 = guidStr.slice(20);
          return str1 + str2 + str3 + str4 + str5;
        };
      }
    }

    let guid = new GUID();
    return guid.newGUID();
  },
  getUserGuid: function () {
    const userType = store.getters && store.getters.userType;
    const key = "$getGUID_login_" + userType;
    let guid = localStorage.getItem(key);
    if (!guid) {
      guid = userType + "-" + this.getGUID();
      localStorage.setItem(key, guid);
    }
    return guid;
  },
  getSignature: function (timeStamp, nonce, token, params) {
    let data = params && params.noDataSign ? "" : JSON.stringify(params);
    //  for (let key in params) {
    //    if (data) data += "&";
    //    data += key + '=' + params[key];
    //  }
    let guid = this.getUserGuid();

    let signStr = timeStamp + nonce + guid + (token ? token.signToken : "") + data;
    signStr = signStr.split("").sort(function (a, b) {
      let v = a > b ? 1 : -1;
      return v;
    });
    signStr = signStr.join("");
    let signResult = md5(signStr).toUpperCase();

    return signResult;
  },
  // 外部API签名
  getSignature2: function (consignorCode, token, timeStamp, nonce, params) {
    try {
      params = JSON.parse(params);
    } catch (e) {
      Message.error("传递数据不是有效的JSON数据");
      return null;
    }
    // 去掉JSON数据的空格
    let data = JSON.stringify(params);
    console.log("json=" + data);

    let signStr = consignorCode + token + timeStamp + nonce + data;
    console.log("排序前：" + signStr);
    signStr = signStr.split("").sort(function (a, b) {
      let v = a > b ? 1 : -1;
      return v;
    });
    signStr = signStr.join("");
    console.log("排序后：" + signStr);
    let signResult = md5(signStr).toUpperCase();

    return signResult;
  },
  // 日期时间格式化
  formatDate: function (date, pattern) {
    if (!date) return "";
    if (!pattern) pattern = "YYYY-MM-DD";
    pattern = pattern.replace("yyyy-MM-dd", "YYYY-MM-DD");
    if (typeof date === "string" && date && date.indexOf("T") > 0) {
      date = new Date(date);
    } else if (typeof date === "number") date = new Date(date);

    return moment(date).format(pattern);
  },
  // 数字格式化
  formatNumber: function (number, pattern, zeroExc, col) {
    try {
      if (isNaN(number)) {
        return null;
      } else if (number === undefined || number === null) {
        return null;
      }

      if (pattern === "%") {
        return Math.Round(Number(number) * 100, 2) + "%";
      } else if (pattern === "人民币大写") {
        let numberRMB = this.convertCurrency(number);
        return numberRMB;
      } else if (pattern === "￥人民币大写") {
        let numberRMB = this.convertCurrency(number);
        return "￥" + numberRMB;
      }

      function _format(pattern, num, z) {
        let j = pattern.length >= num.length ? pattern.length : num.length;
        let p = pattern.split("");
        let n = num.split("");
        let bool = true,
          nn = "";
        for (let i = 0; i < j; i++) {
          let x = n[n.length - j + i];
          let y = p[p.length - j + i];
          if (z === 0) {
            if (bool) {
              if ((x && y && (x !== "0" || y === "0")) || (x && x !== "0" && !y) || (y && y === "0" && !x)) {
                nn += x ? x : "0";
                bool = false;
              }
            } else {
              nn += x ? x : "0";
            }
          } else {
            if (y && (y === "0" || (y === "#" && x))) nn += x ? x : "0";
          }
        }
        return nn;
      }

      function _formatNumber(numChar, pattern) {
        let patterns = pattern.split(".");
        let numChars = numChar.split(".");
        let z = patterns[0].indexOf(",") === -1 ? -1 : patterns[0].length - patterns[0].indexOf(",");
        let num1 = _format(patterns[0].replace(",", ""), numChars[0], 0);
        let num2 = _format(patterns[1] ? patterns[1].split("").reverse().join("") : "", numChars[1] ? numChars[1].split("").reverse().join("") : "", 1);
        num1 = num1.split("").reverse().join("");
        let reCat = eval("/[0-9]{" + (z - 1) + "," + (z - 1) + "}/gi");
        let arrdata = z > -1 ? num1.match(reCat) : undefined;
        if (arrdata && arrdata.length > 0) {
          let w = num1.replace(arrdata.join(""), "");
          num1 = arrdata.join(",") + (w === "" ? "" : ",") + w;
        }
        num1 = num1.split("").reverse().join("");
        return (num1 === "" ? "0" : num1) + (num2 !== "" ? "." + num2.split("").reverse().join("") : "");
      }

      pattern = pattern || "#.##";
      zeroExc = zeroExc || true;
      if (pattern.indexOf(".") >= 0) {
        // 小数长度
        let fractionalLength = pattern.split(".")[1].length;
        number = Math.Round(number, fractionalLength);
      }

      let reCat = /[0#,.]{1,}/gi;
      let _pattern = pattern.match(reCat)[0];
      let numChar = number.toString();
      return !(zeroExc && numChar === 0) ? pattern.replace(_pattern, _formatNumber(numChar, _pattern)) : pattern.replace(_pattern, "0");
    } catch (error) {
      return number;
    }
  },
  // 判断字符串是否为数字
  isNumber(val) {
    let regPos = /^\d+(\.\d+)?$/; // 非负浮点数
    let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; // 负浮点数
    if (regPos.test(val) || regNeg.test(val)) {
      return true;
    } else {
      return false;
    }
  },
  /**
   * 判断当前值是否为对象
   * @param {*} obj 需要判断的对象
   */
  isObject: function (obj) {
    return Object.prototype.toString.call(obj) === "[object Object]";
  },
  /**
   * 判断当前值是否为空对象
   * @param {*} obj 需要判断的对象
   */
  isEmptyObject: function (obj) {
    if (!this.isObject(obj)) return false;

    for (let key in obj) {
      return false;
    }
    return true;
  },
  // 下拉框数据缓存
  dropdownData: {},
  // 加载下拉框数据
  loadDropDown(ddIDs, dataOptions, callback) {
    if (typeof dataOptions === "function") {
      callback = dataOptions;
      dataOptions = {};
    }

    if (!Array.isArray(ddIDs)) {
      ddIDs = "" + ddIDs;
      ddIDs = ddIDs.split(",");
    }
    // 过滤掉已经加载的下拉框，不要重复加载了
    ddIDs = ddIDs.filter(id => {
      return !this.dropdownData["dropdown" + id];
    });
    if (!ddIDs.length) {
      if (typeof callback === "function") {
        callback();
      }

      return;
    }

    let url = "/api/common/loadDropDown";
    let params = Object.assign({}, dataOptions, {
      where: ddIDs.join(",")
    });
    this.ajax(
      url,
      params,
      res => {
        this.showMsg(res);
        if (res.result) {
          this.dropdownData = Object.assign({}, this.dropdownData, res.data);
        }
        if (typeof callback === "function") {
          callback(this.dropdownData);
        }
      },
      false
    );
  },
  /**
   * 获取下拉框列表
   * @param {*} ddId 下拉框ID
   */
  getDropDown(ddId) {
    let ddList = this.dropdownData["dropdown" + ddId];
    if (!ddList) ddList = [];
    return ddList;
  },
  // 翻译下拉框值
  translateText(val, dropdown_Id, row, col) {
    let dataTypeList = ["tinyint", "byte", "int", "int32", "int64", "decimal", "float"];
    if (col && dataTypeList.indexOf(col.dataType) >= 0) {
      val = Number(val);
    }
    if (!col) {
      return val;
    }

    if (col.options && col.options.remote === false) {
      const ddList = col.options.options;
      if (!ddList) return val;
      const item = ddList.find((item, index, arr) => {
        let value = item.value;
        if (dataTypeList.indexOf(col.dataType) >= 0) {
          value = Number(value);
        }
        return value === val;
      });
      if (!item) return val;
      return item.label;
    }

    if (col.options && col.options.remote === "bindDropdown") {
      const _dropdown_Id = col.options.dropdown_Id;
      if (_dropdown_Id > 0) {
        dropdown_Id = _dropdown_Id;
      }
    }

    const ddList = this.dropdownData["dropdown" + dropdown_Id];
    if (!ddList) return val;
    const item = ddList.find((item, index, arr) => {
      return item.value === val;
    });
    if (!item) return val;
    return item.label;
  },
  // SaaS权限
  hasSaaSAuth(name, ref) {
    const url = "/api/auth/hasSaasAuth";
    const params = {
      IDs: name
    };
    this.ajax(url, params, res => {
      let result = res.result;
      if (result) {
        result = res.data.some(item => {
          return item.moduleName === name;
        });
      }
      if (!result) {
        res.msg = "当前模块没有权限操作";
        window.setTimeout(() => {
          ref.classList.add("no-auth");
        }, 1000);
      }
      this.showMsg(res);
    });
  },
  // SaaS权限 - 按钮权限
  isSaaSAuth(nameList, callback) {
    const url = "/api/auth/hasSaasAuth";
    const params = {
      IDs: nameList.join(",")
    };
    this.ajax(url, params, res => {
      if (typeof callback === "function") {
        callback(res);
      }
    });
  },
  // 深度复制
  deepCopy(dataSource) {
    if (!dataSource) return null;

    let data = JSON.stringify(dataSource);
    return JSON.parse(data);
  },
  // NaN显示处理
  isNaN(val) {
    if (val || val === 0) {
      return val;
    } else if (val === null) {
      return "";
    } else {
      return isNaN(val) ? "" : val;
    }
  },
  // 通用tag背景颜色
  getTagBgColor(row, field, value) {
    const tagColorList = field.tagColorList;
    if (!tagColorList) {
      return "#fff";
    }
    const colorItem = tagColorList.find(item => {
      let tagValue = item.value;
      // 取字段值
      if (("" + tagValue).indexOf("row.") === 0) {
        tagValue = eval(tagValue);
      }
      if (["tinyint", "int", "int16", "int32", "int64", "decimal", "float", "double", "byte", "single"].indexOf(field.dataType) >= 0) {
        tagValue = Number(tagValue);
        value = Number(value);
      }
      if (item.operator === "=" && tagValue === value) {
        return true;
      } else if (item.operator === ">=" && value >= tagValue) {
        return true;
      } else if (item.operator === ">" && value > tagValue) {
        return true;
      } else if (item.operator === "<=" && value <= tagValue) {
        return true;
      } else if (item.operator === "<" && value < tagValue) {
        return true;
      } else if (item.operator === "like" && value.indexOf(tagValue) >= 0) {
        return true;
      } else {
        return false;
      }
    });
    let bgColor = "#fff";
    if (colorItem) bgColor = colorItem.backgroudColor;

    return bgColor;
  },
  // 通用tag字体颜色
  getTagColor(row, field, value) {
    const tagColorList = field.tagColorList;
    if (!tagColorList) {
      return {
        border: 0,
        color: "#000"
      };
    }
    const colorItem = tagColorList.find(item => {
      let tagValue = item.value;
      // 取字段值
      if (("" + tagValue).indexOf("row.") === 0) {
        tagValue = eval(tagValue);
      }
      if (["tinyint", "int", "int16", "int32", "int64", "decimal", "float", "double", "byte", "single"].indexOf(field.dataType) >= 0) {
        tagValue = Number(tagValue);
        value = Number(value);
      }
      if (item.operator === "=" && tagValue === value) {
        return true;
      } else if (item.operator === ">=" && value >= tagValue) {
        return true;
      } else if (item.operator === ">" && value > tagValue) {
        return true;
      } else if (item.operator === "<=" && value <= tagValue) {
        return true;
      } else if (item.operator === "<" && value < tagValue) {
        return true;
      } else if (item.operator === "like" && value.indexOf(tagValue) >= 0) {
        return true;
      } else {
        return false;
      }
    });
    let color = "#000";
    if (colorItem) color = colorItem.color;

    return {
      border: 0,
      color: color
    };
  },
  // 格式化数据
  formatData(row, col) {
    let data = "";
    let val = row[col.prop];
    if (["select"].indexOf(col.type) >= 0) {
      // 下拉框转义
      let dropdown_Id = col.options && col.options.dropdown_Id ? col.options.dropdown_Id : col.dropdown_Id;
      data = this.translateText(val, dropdown_Id, row, col);
    } else if (["date", "datetime"].indexOf(col.dataType) >= 0 && col.formatter) {
      data = this.formatDate(val, col.formatter);
    } else if (["byte", "tinyint", "int32", "int64", "decimal", "double"].indexOf(col.dataType) >= 0) {
      data = this.formatNumber(val, col.formatter);
    } else {
      data = val;
    }
    try {
      if (col.script) {
        // eslint-disable-next-line
        eval(col.script);
      }
    } catch (error) {
      this.$message.error(`${col.label}脚本执行错误：${error.messsage}`);
    }
    return data;
  },
  // 将扩展字段值合并到当前对象中
  mergeExpandFields(rowData) {
    // 将对象转为数组
    if (!Array.isArray(rowData)) {
      rowData = [rowData];
    }

    for (let row of rowData) {
      try {
        let v = row["expandFields"];
        if (v) {
          v = JSON.parse(v);
          Object.keys(v).forEach(key => {
            row[key] = v[key];
          });
        }
      } catch (error) {}
    }
  },
  // 币种符号
  getMoneyCode(currencyCode) {
    if (!currencyCode) {
      return;
    }
    if (sessionStorage.getItem("currencyCodes", true)) {
      return this.moneyCode(currencyCode);
    } else {
      const url = "/api/sys/param/getValueList";
      var params = {
        type_Id: 594
      };
      this.ajax(
        url,
        params,
        res => {
          if (res.data && res.data.length) {
            sessionStorage.setItem("currencyCodes", JSON.stringify(res.data));
            currencyCode = this.moneyCode(currencyCode);
          }
        },
        true
      );
      return currencyCode;
    }
  },
  //查询对应的币种符号
  moneyCode(currencyCode) {
    const currencyCodes = JSON.parse(sessionStorage.getItem("currencyCodes", true));
    let info = currencyCodes.find(item => item.value02 == currencyCode);
    if (info) {
      return info.value04;
    }
    return "";
  },
  /**
   * 将数字转为人民币大写
   * @param {Number} money
   */
  convertCurrency(money) {
    //汉字的数字
    let cnNums = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖");
    //基本单位
    let cnIntRadice = new Array("", "拾", "佰", "仟");
    //对应整数部分扩展单位
    let cnIntUnits = new Array("", "万", "亿", "兆");
    //对应小数部分单位
    let cnDecUnits = new Array("角", "分", "毫", "厘");
    //整数金额时后面跟的字符
    let cnInteger = "整";
    //整型完以后的单位
    let cnIntLast = "元";
    //最大处理的数字
    let maxNum = 999999999999999.9999;
    //金额整数部分
    let integerNum;
    //金额小数部分
    let decimalNum;
    //输出的中文金额字符串
    let chineseStr = "";
    //分离金额后用的数组，预定义
    let parts;
    if (money == "") {
      return "";
    }
    money = parseFloat(money);
    if (money >= maxNum) {
      //超出最大处理数字
      return "";
    }

    if (money == 0) {
      chineseStr = cnNums[0] + cnIntLast + cnInteger;
      return chineseStr;
    }
    //转换为字符串
    money = money.toString();
    if (money.indexOf(".") == -1) {
      integerNum = money;
      decimalNum = "";
    } else {
      parts = money.split(".");
      integerNum = parts[0];
      decimalNum = parts[1].substr(0, 4);
    }
    //获取整型部分转换
    if (parseInt(integerNum, 10) > 0) {
      let zeroCount = 0;
      let IntLen = integerNum.length;
      for (let i = 0; i < IntLen; i++) {
        let n = integerNum.substr(i, 1);
        let p = IntLen - i - 1;
        let q = p / 4;
        let m = p % 4;
        if (n == "0") {
          zeroCount++;
        } else {
          if (zeroCount > 0) {
            chineseStr += cnNums[0];
          }
          //归零
          zeroCount = 0;
          chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
        }
        if (m == 0 && zeroCount < 4) {
          chineseStr += cnIntUnits[q];
        }
      }
      chineseStr += cnIntLast;
    }
    //小数部分
    if (decimalNum != "") {
      let decLen = decimalNum.length;
      for (let i = 0; i < decLen; i++) {
        let n = decimalNum.substr(i, 1);
        if (n != "0") {
          chineseStr += cnNums[Number(n)] + cnDecUnits[i];
        }
      }
    }
    if (chineseStr == "") {
      chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum == "") {
      chineseStr += cnInteger;
    }
    return chineseStr;
  }
};

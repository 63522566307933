var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c(
        "div",
        { staticClass: "left-native" },
        [
          _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: {
              "toggle-click": _vm.toggleSideBar,
              "is-active": _vm.sidebar.opened,
            },
          }),
          _c("top-nav", { attrs: { "basic-info": _vm.basicInfo } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _vm.NODE_ENV === "development"
                  ? _c("error-log", {
                      staticClass: "errLog-container right-menu-item",
                    })
                  : _vm._e(),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "right-menu-item",
                    attrs: {
                      content: _vm.$t("navbar.screenfull"),
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("screenfull", {
                      staticClass: "screenfull right-menu-item",
                    }),
                  ],
                  1
                ),
                _c("lang-select", { staticClass: "right-menu-item" }),
              ]
            : _vm._e(),
          _vm.open_time_card
            ? [
                _c("openTimeCard", {
                  staticClass: "screenfull right-menu-item",
                }),
              ]
            : _vm._e(),
          _c("warning", { staticClass: "screenfull right-menu-item" }),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.basicInfo.icon },
                }),
                _c("span", { staticClass: "user-true-name" }, [
                  _vm._v(
                    _vm._s(_vm.$tt(_vm.common.getUserInfo().userTrueName))
                  ),
                ]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("el-dropdown-item", [
                        _c("i", {
                          staticClass: "el-icon-yrt-shouye1",
                          staticStyle: { "margin-right": "5px" },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.$tt("首页")))]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.themeSetting.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticStyle: { "margin-right": "5px" },
                        attrs: { "icon-class": "themeSetting" },
                      }),
                      _c("span", [_vm._v(_vm._s(_vm.$tt("主题设置")))]),
                    ],
                    1
                  ),
                  _c("el-dropdown-item", [
                    _c("i", {
                      staticClass: "el-icon-yrt-icon21",
                      staticStyle: { "margin-right": "5px" },
                    }),
                    _c("span", { on: { click: _vm.modifypwd } }, [
                      _vm._v(_vm._s(_vm.$tt("修改密码"))),
                    ]),
                  ]),
                  _c("el-dropdown-item", [
                    _c("i", {
                      staticClass: "el-icon-yrt-shuaxin1",
                      staticStyle: { "margin-right": "5px" },
                    }),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clearCache()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$tt("清除缓存")))]
                    ),
                  ]),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c("i", {
                      staticClass: "el-icon-yrt-tuihuochuli",
                      staticStyle: { "margin-right": "5px" },
                    }),
                    _c("span", { on: { click: _vm.logout } }, [
                      _vm._v(_vm._s(_vm.$tt("退出系统"))),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("modify-pwd", {
        ref: "modifypwd-dialog",
        attrs: { visible: _vm.modifyPwdVisible, "is-orgin-pwd": "" },
        on: {
          "update:visible": function ($event) {
            _vm.modifyPwdVisible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
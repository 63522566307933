/* eslint-disable */
export const asyncRouterMap = [{
	path: '/sys/basic-info',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'sys-basic-info',
	alwaysShow: true,
	meta: {
		title: 'sys-basic-info',
		icon: 'menu-icon el-icon-yrt-wenjianjia',
		menu_Id: 253,
    menuName: '仓储基础信息管理',
    isOpenWindow: false
	},
	children:[{
		path: '/crm/base/client',
		component: () => import('@/views/crm/base/client'),
		name: 'crm-base-client',
		meta: {
			title: 'crm-base-client',
			icon: '',
			menu_Id: 255,
			menuName: '门店信息管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/basic/consignor',
		component: () => import('@/views/sys/basic/consignor'),
		name: 'sys-basic-consignor',
		meta: {
			title: 'sys-basic-consignor',
			icon: '',
			menu_Id: 501,
			menuName: '货主信息管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/inventory/base_organization',
		component: () => import('@/views/basicInfo/inventory/base_organization'),
		name: 'basicInfo-inventory-base_organization',
		meta: {
			title: 'basicInfo-inventory-base_organization',
			icon: '',
			menu_Id: 2213,
			menuName: '仓储机构管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/inventory/base_producer',
		component: () => import('@/views/basicInfo/inventory/base_producer'),
		name: 'basicInfo-inventory-base_producer',
		meta: {
			title: 'basicInfo-inventory-base_producer',
			icon: '',
			menu_Id: 2214,
			menuName: '生产商管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/inventory/base_contract',
		component: () => import('@/views/basicInfo/inventory/base_contract'),
		name: 'basicInfo-inventory-base_contract',
		meta: {
			title: 'basicInfo-inventory-base_contract',
			icon: '',
			menu_Id: 2215,
			menuName: '合同管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/inventory/base_project',
		component: () => import('@/views/basicInfo/inventory/base_project'),
		name: 'basicInfo-inventory-base_project',
		meta: {
			title: 'basicInfo-inventory-base_project',
			icon: '',
			menu_Id: 2216,
			menuName: '项目信息管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/product-info',
		component: () => import('@/views/sys/product/product-info'),
		name: 'sys-product-product-info',
		meta: {
			title: 'sys-product-product-info',
			icon: '',
			menu_Id: 254,
			menuName: '货物管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/consignor/base-consignorStoreAddress',
		component: () => import('@/views/basicInfo/consignor/base-consignorStoreAddress'),
		name: 'basicInfo-consignor-base-consignorStoreAddress',
		meta: {
			title: 'basicInfo-consignor-base-consignorStoreAddress',
			icon: '',
			menu_Id: 1781,
			menuName: '收货方管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/consignor/base-consignorAddress',
		component: () => import('@/views/basicInfo/consignor/base-consignorAddress'),
		name: 'basicInfo-consignor-base-consignorAddress',
		meta: {
			title: 'basicInfo-consignor-base-consignorAddress',
			icon: '',
			menu_Id: 1789,
			menuName: '发货人管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/provider',
		component: () => import('@/views/sys/product/provider'),
		name: 'sys-product-provider',
		meta: {
			title: 'sys-product-provider',
			icon: '',
			menu_Id: 256,
			menuName: '供应商管理',
      isOpenWindow: false
		}
	},{
		path: '/finance/base/storagefee',
		component: () => import('@/views/finance/base/storagefee'),
		name: 'finance-fee-base-storagefee',
		meta: {
			title: 'finance-fee-base-storagefee',
			icon: '',
			menu_Id: 1616,
			menuName: '仓储费管理',
      isOpenWindow: false
		}
	},{
		path: '/finance/fee/payablebill',
		component: () => import('@/views/finance/fee/payablebill'),
		name: 'finance-fee-payablebill',
		meta: {
			title: 'finance-fee-payablebill',
			icon: '',
			menu_Id: 1785,
			menuName: '账单结算',
      isOpenWindow: false
		}
	}]
},{
	path: '/storage',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'storage',
	alwaysShow: true,
	meta: {
		title: 'storage',
		icon: 'menu-icon el-icon-yrt-kucunchaxun',
		menu_Id: 135,
    menuName: '仓库配置管理',
    isOpenWindow: false
	},
	children:[{
		path: '/basicInfo/inventory/base_outsource',
		component: () => import('@/views/basicInfo/inventory/base_outsource'),
		name: 'basicInfo-inventory-base_outsource',
		meta: {
			title: 'basicInfo-inventory-base_outsource',
			icon: '',
			menu_Id: 2217,
			menuName: '外包管理',
      isOpenWindow: false
		}
	},{
		path: '/storage/setting/storage',
		component: () => import('@/views/storage/setting/storage'),
		name: 'storage-setting-storage',
		meta: {
			title: 'storage-setting-storage',
			icon: '',
			menu_Id: 258,
			menuName: '仓库设置',
      isOpenWindow: false
		}
	},{
		path: '/storage/setting/storage-area',
		component: () => import('@/views/storage/setting/storage-area'),
		name: 'storage-setting-storage-area',
		meta: {
			title: 'storage-setting-storage-area',
			icon: '',
			menu_Id: 1561,
			menuName: '库区设置',
      isOpenWindow: false
		}
	},{
		path: 'position',
		component: () => import('@/views/storage/setting/position'),
		name: 'storage-setting-position',
		meta: {
			title: 'storage-setting-position',
			icon: '',
			menu_Id: 259,
			menuName: '储位设置',
      isOpenWindow: false
		}
	},{
		path: 'position-config',
		component: () => import('@/views/storage/setting/position-config'),
		name: 'position-config',
		meta: {
			title: 'position-config',
			icon: '',
			menu_Id: 1560,
			menuName: '储位定义',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/x6-designer',
		component: () => import('@/views/sys/dev-tools/x6-designer'),
		name: 'sys-dev-tools-x6-designer',
		meta: {
			title: 'sys-dev-tools-x6-designer',
			icon: '',
			menu_Id: 433,
			menuName: '库位图设计器',
      isOpenWindow: false
		}
	},{
		path: '/storage/base/product-position-name',
		component: () => import('@/views/storage/base/product-position-name'),
		name: 'storage-base-product-position-name',
		meta: {
			title: 'storage-base-product-position-name',
			icon: '',
			menu_Id: 145,
			menuName: '状态管理',
      isOpenWindow: false
		}
	},{
		path: '/storage/setting/shelve-regular',
		component: () => import('@/views/storage/setting/shelve-regular'),
		name: 'storage-setting-shelve-regular',
		meta: {
			title: 'storage-setting-shelve-regular',
			icon: '',
			menu_Id: 1572,
			menuName: '规则设置',
      isOpenWindow: false
		}
	}]
},{
	path: 'setting',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'setting-allocate',
	alwaysShow: true,
	meta: {
		title: 'setting-allocate',
		icon: 'menu-icon el-icon-yrt-cogs3',
		menu_Id: 244,
    menuName: '溯源管理',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/product/product-info?id=431&name=产品列表',
		component: () => import('@/views/sys/product/product-info'),
		name: 'sys-product-product-info',
		meta: {
			title: 'sys-product-product-info',
			icon: '',
			menu_Id: 431,
			menuName: '产品列表',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/product-security',
		component: () => import('@/views/sys/product/product-security'),
		name: 'sys-product-product-security',
		meta: {
			title: 'sys-product-product-security',
			icon: '',
			menu_Id: 2309,
			menuName: '产品批次',
      isOpenWindow: false
		}
	},{
		path: '/inbound/purchase/order?153&name=绑定溯源码',
		component: () => import('@/views/inbound/purchase/order?153'),
		name: 'inbound-purchase-order?153',
		meta: {
			title: 'inbound-purchase-order?153',
			icon: '',
			menu_Id: 153,
			menuName: '绑定溯源码',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/type',
		component: () => import('@/views/sys/product/type'),
		name: 'sys-product-type',
		meta: {
			title: 'sys-product-type',
			icon: '',
			menu_Id: 261,
			menuName: '分类管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/permission/user?id=2164&name=流程管理',
		component: () => import('@/views/sys/permission/user'),
		name: 'sys-permission-user',
		meta: {
			title: 'sys-permission-user',
			icon: '',
			menu_Id: 2164,
			menuName: '流程管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/vproduct-security-detail',
		component: () => import('@/views/sys/product/vproduct-security-detail'),
		name: 'sys-product-vproduct-security-detail',
		meta: {
			title: 'sys-product-vproduct-security-detail',
			icon: '',
			menu_Id: 2311,
			menuName: '防伪码列表',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-orderlist-client?1559&name=流程状态',
		component: () => import('@/views/stat/out/vsale-orderlist-client?1559&name=流程状态'),
		name: 'stat-out-vsale-orderlist-client?1559&name=流程状态',
		meta: {
			title: 'stat-out-vsale-orderlist-client?1559&name=流程状态',
			icon: '',
			menu_Id: 1559,
			menuName: '流程状态',
      isOpenWindow: false
		}
	},{
		path: '/crm/base/corperation',
		component: () => import('@/views/crm/base/corperation'),
		name: 'crm-base-corperation',
		meta: {
			title: 'crm-base-corperation',
			icon: '',
			menu_Id: 1582,
			menuName: '企业简介',
      isOpenWindow: false
		}
	},{
		path: '/sys/product/product-price',
		component: () => import('@/views/sys/product/product-price'),
		name: 'sys-product-product-price',
		meta: {
			title: 'sys-product-product-price',
			icon: '',
			menu_Id: 1856,
			menuName: '产品统计',
      isOpenWindow: false
		}
	},{
		path: '/sys/permission/role?1562',
		component: () => import('@/views/sys/permission/role'),
		name: 'sys-permission-role',
		meta: {
			title: 'sys-permission-role',
			icon: '',
			menu_Id: 1562,
			menuName: '角色列表',
      isOpenWindow: false
		}
	},{
		path: '/sys/permission/user?id=2164&name=账号列表',
		component: () => import('@/views/sys/permission/user'),
		name: 'sys-permission-user',
		meta: {
			title: 'sys-permission-user',
			icon: '',
			menu_Id: 1680,
			menuName: '账号列表',
      isOpenWindow: false
		}
	},{
		path: '/sys/permission/user?id=2165&name=企业账号',
		component: () => import('@/views/sys/permission/user'),
		name: 'sys-permission-user',
		meta: {
			title: 'sys-permission-user',
			icon: '',
			menu_Id: 2165,
			menuName: '企业账号',
      isOpenWindow: false
		}
	}]
},{
	path: '/inbound',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'inbound',
	alwaysShow: true,
	meta: {
		title: 'inbound',
		icon: 'menu-icon el-icon-yrt-ruku1',
		menu_Id: 101,
    menuName: '仓储作业管理',
    isOpenWindow: false
	},
	children:[{
	path: 'purchase',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'purchase-base',
	alwaysShow: true,
	meta: {
		title: 'purchase-base',
		icon: '',
		menu_Id: 212,
    menuName: '收货单据',
    isOpenWindow: false
	},
	children:[{
		path: '/inbound/purchase/quotation',
		component: () => import('@/views/inbound/purchase/quotation'),
		name: 'inbound-purchase-quotation',
		meta: {
			title: 'inbound-purchase-quotation',
			icon: '',
			menu_Id: 301,
			menuName: '入库计划查询',
      isOpenWindow: false
		}
	},{
		path: 'order',
		component: () => import('@/views/inbound/purchase/order'),
		name: 'inbound-purchase-order',
		meta: {
			title: 'inbound-purchase-order',
			icon: '',
			menu_Id: 102,
			menuName: '预到货单',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/order-pai-scan',
		component: () => import('@/views/inbound/scan/order-pai-scan'),
		name: 'inbound-scan-order-pai-scan',
		meta: {
			title: 'inbound-scan-order-pai-scan',
			icon: '',
			menu_Id: 1685,
			menuName: '卸货扫描',
      isOpenWindow: false
		}
	},{
		path: '/inbound/purchase/enter',
		component: () => import('@/views/inbound/purchase/enter'),
		name: 'inbound-purchase-enter',
		meta: {
			title: 'inbound-purchase-enter',
			icon: '',
			menu_Id: 103,
			menuName: '卸货登记',
      isOpenWindow: false
		}
	},{
		path: '/inbound/scan/shelvePai',
		component: () => import('@/views/inbound/scan/shelvePai'),
		name: '/inbound/scan/shelvePai',
		meta: {
			title: '/inbound/scan/shelvePai',
			icon: '',
			menu_Id: 184,
			menuName: '进库扫描',
      isOpenWindow: false
		}
	},{
		path: '/inbound/purchase/shelve',
		component: () => import('@/views/inbound/purchase/shelve'),
		name: 'inbound-purchase-shelve',
		meta: {
			title: 'inbound-purchase-shelve',
			icon: '',
			menu_Id: 269,
			menuName: '进库登记',
      isOpenWindow: false
		}
	}]
},{
	path: 'sale',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'sale-base',
	alwaysShow: true,
	meta: {
		title: 'sale-base',
		icon: '',
		menu_Id: 316,
    menuName: '出库单据',
    isOpenWindow: false
	},
	children:[{
		path: '/outbound/sale/order-plan',
		component: () => import('@/views/outbound/sale/order-plan'),
		name: 'outbound-sale-order-plan',
		meta: {
			title: 'outbound-sale-order-plan',
			icon: '',
			menu_Id: 302,
			menuName: 'PDA出库开单',
      isOpenWindow: false
		}
	},{
		path: 'order',
		component: () => import('@/views/outbound/sale/order'),
		name: 'outbound-sale-order',
		meta: {
			title: 'outbound-sale-order',
			icon: '',
			menu_Id: 112,
			menuName: '出库订单',
      isOpenWindow: false
		}
	},{
		path: '/outbound/operation/create-order-batch',
		component: () => import('@/views/outbound/operation/create-order-batch'),
		name: 'outbound-operation-create-order-batch',
		meta: {
			title: 'outbound-operation-create-order-batch',
			icon: '',
			menu_Id: 380,
			menuName: '生成波次',
      isOpenWindow: false
		}
	},{
		path: '/outbound/operation/order-batch',
		component: () => import('@/views/outbound/operation/order-batch'),
		name: 'outbound-operation-order-batch',
		meta: {
			title: 'outbound-operation-order-batch',
			icon: '',
			menu_Id: 248,
			menuName: '波次查询',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/order-picking',
		component: () => import('@/views/outbound/scan/order-picking'),
		name: 'outbound-scan-order-picking',
		meta: {
			title: 'outbound-scan-order-picking',
			icon: '',
			menu_Id: 249,
			menuName: '商品出库验证',
      isOpenWindow: false
		}
	},{
		path: '/outbound/sale/orderPicking',
		component: () => import('@/views/outbound/sale/orderPicking'),
		name: 'outbound-sale-orderPicking',
		meta: {
			title: 'outbound-sale-orderPicking',
			icon: '',
			menu_Id: 116,
			menuName: '出库登记',
      isOpenWindow: false
		}
	},{
		path: '/outbound/scan/out',
		component: () => import('@/views/outbound/scan/out'),
		name: 'outbound-scan-out',
		meta: {
			title: 'outbound-scan-out',
			icon: '',
			menu_Id: 113,
			menuName: '装车校验',
      isOpenWindow: false
		}
	},{
		path: '/outbound/sale/outer',
		component: () => import('@/views/outbound/sale/outer'),
		name: 'outbound-sale-outer',
		meta: {
			title: 'outbound-sale-outer',
			icon: '',
			menu_Id: 268,
			menuName: '装车登记',
      isOpenWindow: false
		}
	}]
},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=71',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1905',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1905',
			icon: '',
			menu_Id: 1905,
			menuName: '卸货作业时长预警',
      isOpenWindow: false
		}
	}]
},{
	path: 'operation',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'operation',
	alwaysShow: true,
	meta: {
		title: 'operation',
		icon: 'menu-icon el-icon-yrt-cangchuguanli',
		menu_Id: 117,
    menuName: '仓储库内管理',
    isOpenWindow: false
	},
	children:[{
		path: '/storage/base/product-position',
		component: () => import('@/views/storage/base/product-position'),
		name: 'storage-base-product-position',
		meta: {
			title: 'storage-base-product-position',
			icon: '',
			menu_Id: 385,
			menuName: '扫码库存查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/base/product-position-history',
		component: () => import('@/views/storage/base/product-position-history'),
		name: 'storage-base-product-position-history',
		meta: {
			title: 'storage-base-product-position-history',
			icon: '',
			menu_Id: 271,
			menuName: '安全库存提示',
      isOpenWindow: false
		}
	},{
	path: '/storage/transfer/position-transfer',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'storage-transfer-position-transfer',
	alwaysShow: true,
	meta: {
		title: 'storage-transfer-position-transfer',
		icon: '',
		menu_Id: 450,
    menuName: '盘点管理',
    isOpenWindow: false
	},
	children:[{
		path: '/storage/check/create-check',
		component: () => import('@/views/storage/check/create-check'),
		name: 'storage-check-create-check',
		meta: {
			title: 'storage-check-create-check',
			icon: '',
			menu_Id: 1528,
			menuName: '生成盘点单',
      isOpenWindow: false
		}
	},{
		path: '/storage/check/check',
		component: () => import('@/views/storage/check/check'),
		name: 'storage-check-check',
		meta: {
			title: 'storage-check-check',
			icon: '',
			menu_Id: 147,
			menuName: '库存盘点查询',
      isOpenWindow: false
		}
	},{
		path: '/storage/check/profit-loss',
		component: () => import('@/views/storage/check/profit-loss'),
		name: 'storage-check-profit-loss',
		meta: {
			title: 'storage-check-profit-loss',
			icon: '',
			menu_Id: 1565,
			menuName: '盘点盈亏单',
      isOpenWindow: false
		}
	}]
},{
	path: 'allocate',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'allocate',
	alwaysShow: true,
	meta: {
		title: 'allocate',
		icon: '',
		menu_Id: 138,
    menuName: '移库管理',
    isOpenWindow: false
	},
	children:[{
		path: '/storage/allocate/apply',
		component: () => import('@/views/storage/allocate/apply'),
		name: 'storage-allocate-apply',
		meta: {
			title: 'storage-allocate-apply',
			icon: '',
			menu_Id: 136,
			menuName: '移库调拨申请',
      isOpenWindow: false
		}
	},{
		path: '/storage/allocate/enter',
		component: () => import('@/views/storage/allocate/enter'),
		name: 'storage-allocate-enter',
		meta: {
			title: 'storage-allocate-enter',
			icon: '',
			menu_Id: 137,
			menuName: '移库调拨入库',
      isOpenWindow: false
		}
	}]
},{
		path: '/storage/base/product-placeholder',
		component: () => import('@/views/storage/base/product-placeholder'),
		name: 'storage-base-product-placeholder',
		meta: {
			title: 'storage-base-product-placeholder',
			icon: '',
			menu_Id: 272,
			menuName: '库存解锁',
      isOpenWindow: false
		}
	},{
		path: '/storage/transfer/consignor-transfer',
		component: () => import('@/views/storage/transfer/consignor-transfer'),
		name: 'storage-transfer-consignor-transfer',
		meta: {
			title: 'storage-transfer-consignor-transfer',
			icon: '',
			menu_Id: 1686,
			menuName: '货主转移',
      isOpenWindow: false
		}
	},{
		path: '/stat/inventory/early',
		component: () => import('@/views/stat/inventory/early'),
		name: 'v-shelflife-early-warning',
		meta: {
			title: 'v-shelflife-early-warning',
			icon: '',
			menu_Id: 1570,
			menuName: '保质期预警',
      isOpenWindow: false
		}
	},{
		path: '/stat/inventory/warning',
		component: () => import('@/views/stat/inventory/warning'),
		name: 'v-old-library-early-warning',
		meta: {
			title: 'v-old-library-early-warning',
			icon: '',
			menu_Id: 1571,
			menuName: '库龄预警',
      isOpenWindow: false
		}
	},{
		path: '/stat/inventory/lower',
		component: () => import('@/views/stat/inventory/lower'),
		name: 'v-base-product-position-lower',
		meta: {
			title: 'v-base-product-position-lower',
			icon: '',
			menu_Id: 1720,
			menuName: '货位最低库存预警',
      isOpenWindow: false
		}
	}]
},{
	path: '/stat',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'stat',
	alwaysShow: true,
	meta: {
		title: 'stat',
		icon: 'menu-icon el-icon-yrt-tongji',
		menu_Id: 252,
    menuName: '仓储统计结算',
    isOpenWindow: false
	},
	children:[{
	path: 'stat-receive-base',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'stat-receive-base',
	alwaysShow: true,
	meta: {
		title: 'stat-receive-base',
		icon: '',
		menu_Id: 108,
    menuName: '收货查询',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/receive/vpurchase-order-list',
		component: () => import('@/views/stat/receive/vpurchase-order-list'),
		name: 'vpurchase-order-list',
		meta: {
			title: 'vpurchase-order-list',
			icon: '',
			menu_Id: 12,
			menuName: '预到货明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-enter-list',
		component: () => import('@/views/stat/receive/vpurchase-enter-list'),
		name: 'vpurchase-enter-list',
		meta: {
			title: 'vpurchase-enter-list',
			icon: '',
			menu_Id: 107,
			menuName: '入库明细',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vtms-quotation-list',
		component: () => import('@/views/stat/receive/vtms-quotation-list'),
		name: 'vtms-quotation-list',
		meta: {
			title: 'vtms-quotation-list',
			icon: '',
			menu_Id: 448,
			menuName: '入库计划明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-shelve-list',
		component: () => import('@/views/stat/receive/vpurchase-shelve-list'),
		name: 'stat-receive-vpurchase-shelve-list',
		meta: {
			title: 'stat-receive-vpurchase-shelve-list',
			icon: '',
			menu_Id: 1602,
			menuName: '上架明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-enterlist-product',
		component: () => import('@/views/stat/receive/vpurchase-enterlist-product'),
		name: 'vpurchase-enterlist-product',
		meta: {
			title: 'vpurchase-enterlist-product',
			icon: '',
			menu_Id: 109,
			menuName: '商品进货统计',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-order-arrived',
		component: () => import('@/views/stat/receive/vpurchase-order-arrived'),
		name: 'stat-receive-vpurchase-order-arrived',
		meta: {
			title: 'stat-receive-vpurchase-order-arrived',
			icon: '',
			menu_Id: 165,
			menuName: '预到货单到期报警',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-enterList-report',
		component: () => import('@/views/stat/receive/vpurchase-enterList-report'),
		name: 'vpurchase-enterList-report',
		meta: {
			title: 'vpurchase-enterList-report',
			icon: '',
			menu_Id: 478,
			menuName: '收货入库报表',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-orderList-report',
		component: () => import('@/views/stat/receive/vpurchase-orderList-report'),
		name: 'stat-receive-vpurchase-orderList-report',
		meta: {
			title: 'stat-receive-vpurchase-orderList-report',
			icon: '',
			menu_Id: 480,
			menuName: '预到货单查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-return-list',
		component: () => import('@/views/stat/receive/vpurchase-return-list'),
		name: 'vpurchase-return-list',
		meta: {
			title: 'vpurchase-return-list',
			icon: '',
			menu_Id: 1548,
			menuName: '收货退货查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-out-base',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'stat-out-base',
	alwaysShow: true,
	meta: {
		title: 'stat-out-base',
		icon: '',
		menu_Id: 120,
    menuName: '出库查询',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/out/vsale-outer-list',
		component: () => import('@/views/stat/out/vsale-outer-list'),
		name: 'vsale-outer-list',
		meta: {
			title: 'vsale-outer-list',
			icon: '',
			menu_Id: 122,
			menuName: '出库打包明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-outer-case',
		component: () => import('@/views/stat/out/vsale-outer-case'),
		name: 'vsale-outer-case',
		meta: {
			title: 'vsale-outer-case',
			icon: '',
			menu_Id: 123,
			menuName: '打包装箱查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-order-list',
		component: () => import('@/views/stat/out/vsale-order-list'),
		name: 'vsale-order-list',
		meta: {
			title: 'vsale-order-list',
			icon: '',
			menu_Id: 121,
			menuName: '出库明细',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-sendlist-report',
		component: () => import('@/views/stat/out/vsale-sendlist-report'),
		name: 'vsale-sendlist-report',
		meta: {
			title: 'vsale-sendlist-report',
			icon: '',
			menu_Id: 481,
			menuName: '出货报表',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-order-plan-detail',
		component: () => import('@/views/stat/out/vsale-order-plan-detail'),
		name: 'vsale-order-plan-detail',
		meta: {
			title: 'vsale-order-plan-detail',
			icon: '',
			menu_Id: 482,
			menuName: '出库计划明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-return-list',
		component: () => import('@/views/stat/out/vsale-return-list'),
		name: 'vsale-return-list',
		meta: {
			title: 'vsale-return-list',
			icon: '',
			menu_Id: 1551,
			menuName: '出库单退货查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-orderpickinglist',
		component: () => import('@/views/stat/out/vsale-orderpickinglist'),
		name: 'stat-out-vsale-orderpickinglist',
		meta: {
			title: 'stat-out-vsale-orderpickinglist',
			icon: '',
			menu_Id: 2036,
			menuName: '拣货下架明细查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-storage-base',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'stat-storage-base',
	alwaysShow: true,
	meta: {
		title: 'stat-storage-base',
		icon: '',
		menu_Id: 1533,
    menuName: '库存统计分析',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/storage/xstat-storageday',
		component: () => import('@/views/stat/storage/xstat-storageday'),
		name: 'xstat-storageday',
		meta: {
			title: 'xstat-storageday',
			icon: '',
			menu_Id: 216,
			menuName: '库存报表',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/stat-storageday',
		component: () => import('@/views/stat/storage/stat-storageday'),
		name: 'stat-storageday',
		meta: {
			title: 'stat-storageday',
			icon: '',
			menu_Id: 213,
			menuName: '每日商品库存查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/stat-storageday-details',
		component: () => import('@/views/stat/storage/stat-storageday-details'),
		name: 'stat-storageday-details',
		meta: {
			title: 'stat-storageday-details',
			icon: '',
			menu_Id: 215,
			menuName: '每日库存明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/vstorage-outer-list',
		component: () => import('@/views/stat/storage/vstorage-outer-list'),
		name: 'vstorage-outer-list',
		meta: {
			title: 'vstorage-outer-list',
			icon: '',
			menu_Id: 1535,
			menuName: '其他出库明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/vstorage-enter-list',
		component: () => import('@/views/stat/storage/vstorage-enter-list'),
		name: 'vstorage-enter-list',
		meta: {
			title: 'vstorage-enter-list',
			icon: '',
			menu_Id: 1536,
			menuName: '其他入库明细查询',
      isOpenWindow: false
		}
	},{
		path: '/stat/storage/xstat-storagemonth',
		component: () => import('@/views/stat/storage/xstat-storagemonth'),
		name: 'xstat-storagemonth',
		meta: {
			title: 'xstat-storagemonth',
			icon: '',
			menu_Id: 1812,
			menuName: '上个月与本月报表',
      isOpenWindow: false
		}
	}]
},{
	path: 'finance-receive-payout',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'finance-receive-payout',
	alwaysShow: true,
	meta: {
		title: 'finance-receive-payout',
		icon: '',
		menu_Id: 1694,
    menuName: '应收款报表',
    isOpenWindow: false
	},
	children:[{
		path: '/finance/base/finance-bankaccount',
		component: () => import('@/views/finance/base/finance-bankaccount'),
		name: 'finance-base-finance-bankaccount',
		meta: {
			title: 'finance-base-finance-bankaccount',
			icon: '',
			menu_Id: 1696,
			menuName: '银行账户',
      isOpenWindow: false
		}
	},{
		path: '/finance/pay/finance-payout',
		component: () => import('@/views/finance/pay/finance-payout'),
		name: 'finance-pay-finance-payout',
		meta: {
			title: 'finance-pay-finance-payout',
			icon: '',
			menu_Id: 1703,
			menuName: '付款单',
      isOpenWindow: false
		}
	},{
		path: '/finance/receive/finance-receive',
		component: () => import('@/views/finance/receive/finance-receive'),
		name: 'finance-receive-finance-receive',
		meta: {
			title: 'finance-receive-finance-receive',
			icon: '',
			menu_Id: 1704,
			menuName: '收款单',
      isOpenWindow: false
		}
	}]
},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=70',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1896',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1896',
			icon: '',
			menu_Id: 1896,
			menuName: '周转量统计',
      isOpenWindow: false
		}
	}]
},{
	path: '/finance',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'finance',
	alwaysShow: true,
	meta: {
		title: 'finance',
		icon: 'menu-icon  el-icon-yrt-youxianzijin',
		menu_Id: 1692,
    menuName: '仓储管理平台',
    isOpenWindow: false
	},
	children:[{
	path: 'finance-invoice',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'finance-invoice',
	alwaysShow: true,
	meta: {
		title: 'finance-invoice',
		icon: '',
		menu_Id: 1933,
    menuName: '人员数据',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/report-out/vsale-orderpicking-workloadstatistics',
		component: () => import('@/views/stat/report-out/vsale-orderpicking-workloadstatistics'),
		name: 'vsale-orderpicking-workloadstatistics',
		meta: {
			title: 'vsale-orderpicking-workloadstatistics',
			icon: '',
			menu_Id: 1718,
			menuName: '拣货人员工作量统计',
      isOpenWindow: false
		}
	},{
		path: '/stat/report-out/vsale-orderpicking-list',
		component: () => import('@/views/stat/report-out/vsale-orderpicking-list'),
		name: 'vsale-orderpicking-list',
		meta: {
			title: 'vsale-orderpicking-list',
			icon: '',
			menu_Id: 1719,
			menuName: '拣货人员统计',
      isOpenWindow: false
		}
	}]
},{
	path: 'finance_receipt',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'finance-receipt',
	alwaysShow: true,
	meta: {
		title: 'finance-receipt',
		icon: '',
		menu_Id: 2028,
    menuName: '仓储数据',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/dailyquery/dailyinventoryclassifi',
		component: () => import('@/views/stat/dailyquery/dailyinventoryclassifi'),
		name: 'stat-dailyquery-dailyinventoryclassifi',
		meta: {
			title: 'stat-dailyquery-dailyinventoryclassifi',
			icon: '',
			menu_Id: 1830,
			menuName: '每日库存分类统计图',
      isOpenWindow: false
		}
	},{
		path: '/stat/dailyquery/dailyoutstorageclassifi',
		component: () => import('@/views/stat/dailyquery/dailyoutstorageclassifi'),
		name: 'stat-dailyquery-dailyoutstorageclassifi',
		meta: {
			title: 'stat-dailyquery-dailyoutstorageclassifi',
			icon: '',
			menu_Id: 1831,
			menuName: '每日出库分类统计图',
      isOpenWindow: false
		}
	},{
		path: '/stat/dailyquery/dailystorageclassifi',
		component: () => import('@/views/stat/dailyquery/dailystorageclassifi'),
		name: 'stat-dailyquery-dailystorageclassifi',
		meta: {
			title: 'stat-dailyquery-dailystorageclassifi',
			icon: '',
			menu_Id: 1832,
			menuName: '每日入库分类统计图',
      isOpenWindow: false
		}
	},{
		path: '/stat/summary/typesummary',
		component: () => import('@/views/stat/summary/typesummary'),
		name: 'stat-summary-typesummary',
		meta: {
			title: 'stat-summary-typesummary',
			icon: '',
			menu_Id: 1815,
			menuName: '类别明细汇总统计',
      isOpenWindow: false
		}
	},{
		path: '/stat/query/typeclassifcount',
		component: () => import('@/views/stat/query/typeclassifcount'),
		name: 'stat-query-typeclassifcount',
		meta: {
			title: 'stat-query-typeclassifcount',
			icon: '',
			menu_Id: 1828,
			menuName: '分类统计柱状图',
      isOpenWindow: false
		}
	}]
},{
	path: 'performance',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'performance',
	alwaysShow: true,
	meta: {
		title: 'performance',
		icon: '',
		menu_Id: 1618,
    menuName: '绩效管理',
    isOpenWindow: false
	},
	children:[{
		path: '/finance/performance/item',
		component: () => import('@/views/finance/performance/item'),
		name: 'finance-performance-item',
		meta: {
			title: 'finance-performance-item',
			icon: '',
			menu_Id: 1620,
			menuName: '考核科目',
      isOpenWindow: false
		}
	},{
		path: '/finance/performance/template',
		component: () => import('@/views/finance/performance/template'),
		name: 'finance-performance-template',
		meta: {
			title: 'finance-performance-template',
			icon: '',
			menu_Id: 1621,
			menuName: '考核模板',
      isOpenWindow: false
		}
	},{
		path: '/finance/performance/flow',
		component: () => import('@/views/finance/performance/flow'),
		name: 'finance-performance-flow',
		meta: {
			title: 'finance-performance-flow',
			icon: '',
			menu_Id: 1767,
			menuName: '人员绩效流水',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-report-base',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'stat-report-base',
	alwaysShow: true,
	meta: {
		title: 'stat-report-base',
		icon: '',
		menu_Id: 1604,
    menuName: '入库决策管理',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/report/vpurchase-enter-ranking',
		component: () => import('@/views/stat/report/vpurchase-enter-ranking'),
		name: 'vpurchase-enter-ranking',
		meta: {
			title: 'vpurchase-enter-ranking',
			icon: '',
			menu_Id: 1606,
			menuName: '商品入库排名报表(年)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report/vpurchase-enter-rankbymonth',
		component: () => import('@/views/stat/report/vpurchase-enter-rankbymonth'),
		name: 'vpurchase-enter-rankbymonth',
		meta: {
			title: 'vpurchase-enter-rankbymonth',
			icon: '',
			menu_Id: 1610,
			menuName: '商品入库排名报表(月)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report/vpurchase-enter-rankbyweek',
		component: () => import('@/views/stat/report/vpurchase-enter-rankbyweek'),
		name: 'vpurchase-enter-rankbyweek',
		meta: {
			title: 'vpurchase-enter-rankbyweek',
			icon: '',
			menu_Id: 1613,
			menuName: '商品入库排名报表(周)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report/vpurchase-enter-rankbyday',
		component: () => import('@/views/stat/report/vpurchase-enter-rankbyday'),
		name: 'vpurchase-enter-rankbyday',
		meta: {
			title: 'vpurchase-enter-rankbyday',
			icon: '',
			menu_Id: 1614,
			menuName: '商品入库排名报表(天)',
      isOpenWindow: false
		}
	}]
},{
	path: 'stat-out-report-base',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'stat-out-report-base',
	alwaysShow: true,
	meta: {
		title: 'stat-out-report-base',
		icon: '',
		menu_Id: 1605,
    menuName: '出库决策管理',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/report-out/vsale-ranking',
		component: () => import('@/views/stat/report-out/vsale-ranking'),
		name: 'vsale-ranking',
		meta: {
			title: 'vsale-ranking',
			icon: '',
			menu_Id: 1607,
			menuName: '商品出库排名报表(年)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report-out/vsale-rankbymonth',
		component: () => import('@/views/stat/report-out/vsale-rankbymonth'),
		name: 'vsale-rankbymonth',
		meta: {
			title: 'vsale-rankbymonth',
			icon: '',
			menu_Id: 1611,
			menuName: '商品出库排名报表(月)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report-out/vsale-rankbyweek',
		component: () => import('@/views/stat/report-out/vsale-rankbyweek'),
		name: 'vsale-rankbyweek',
		meta: {
			title: 'vsale-rankbyweek',
			icon: '',
			menu_Id: 1612,
			menuName: '商品出库排名报表(周)',
      isOpenWindow: false
		}
	},{
		path: '/stat/report-out/vsale-rankbyday',
		component: () => import('@/views/stat/report-out/vsale-rankbyday'),
		name: 'vsale-rankbyday',
		meta: {
			title: 'vsale-rankbyday',
			icon: '',
			menu_Id: 1615,
			menuName: '商品出库排名报表(天)',
      isOpenWindow: false
		}
	}]
},{
	path: 'fee',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'fee',
	alwaysShow: true,
	meta: {
		title: 'fee',
		icon: '',
		menu_Id: 1769,
    menuName: '运营数据',
    isOpenWindow: false
	},
	children:[{
		path: '/stat/receive/vpurchase-enterlist-provider',
		component: () => import('@/views/stat/receive/vpurchase-enterlist-provider'),
		name: 'vpurchase-enterlist-provider',
		meta: {
			title: 'vpurchase-enterlist-provider',
			icon: '',
			menu_Id: 164,
			menuName: '供应商进货统计',
      isOpenWindow: false
		}
	},{
		path: '/stat/out/vsale-orderlist-client',
		component: () => import('@/views/stat/out/vsale-orderlist-client'),
		name: 'vsale-orderlist-client',
		meta: {
			title: 'vsale-orderlist-client',
			icon: '',
			menu_Id: 348,
			menuName: '客户销售查询',
      isOpenWindow: false
		}
	}]
},{
		path: '/storage/stat/stat-manage',
		component: () => import('@/views/storage/stat/stat-manage'),
		name: 'storage-stat-stat-manage',
		meta: {
			title: 'storage-stat-stat-manage',
			icon: '',
			menu_Id: 2262,
			menuName: '项目管理(报表)',
      isOpenWindow: false
		}
	}]
},{
	path: 'asset',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'asset',
	alwaysShow: true,
	meta: {
		title: 'asset',
		icon: 'menu-icon  el-icon-yrt-zijinlicai',
		menu_Id: 472,
    menuName: '仓储工作量管理',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=57&name=工作量维护',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer',
		meta: {
			title: 'sys-dev-tools-bi-viewer',
			icon: '',
			menu_Id: 1662,
			menuName: '工作量维护',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=60&name=工作效率维护',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer',
		meta: {
			title: 'sys-dev-tools-bi-viewer',
			icon: '',
			menu_Id: 1695,
			menuName: '工作效率维护',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=61&name=工作效率维护',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer',
		meta: {
			title: 'sys-dev-tools-bi-viewer',
			icon: '',
			menu_Id: 1708,
			menuName: '工作量查询',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=68&name=工作效率维护',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer',
		meta: {
			title: 'sys-dev-tools-bi-viewer',
			icon: '',
			menu_Id: 1709,
			menuName: '设备利用率',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=69&name=工作效率维护',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer',
		meta: {
			title: 'sys-dev-tools-bi-viewer',
			icon: '',
			menu_Id: 1710,
			menuName: '操作用时查询',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=76&name=历程统计',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer',
		meta: {
			title: 'sys-dev-tools-bi-viewer',
			icon: '',
			menu_Id: 1914,
			menuName: '历程统计',
      isOpenWindow: false
		}
	}]
},{
	path: 'cptms-car',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'cptms-car',
	alwaysShow: true,
	meta: {
		title: 'cptms-car',
		icon: 'menu-icon el-icon-yrt-sijiguanli',
		menu_Id: 1768,
    menuName: '司机&车辆管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/site/tms-line',
		component: () => import('@/views/tms/site/tms-line'),
		name: 'tms-site-tms-line',
		meta: {
			title: 'tms-site-tms-line',
			icon: '',
			menu_Id: 1799,
			menuName: '线路管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/biz/tms-distribution-account',
		component: () => import('@/views/tms/biz/tms-distribution-account'),
		name: 'tms-biz-tms-distribution-account',
		meta: {
			title: 'tms-biz-tms-distribution-account',
			icon: '',
			menu_Id: 2166,
			menuName: '费用管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/vehicle/tms-repair',
		component: () => import('@/views/tms/vehicle/tms-repair'),
		name: 'tms-vehicle-tms-repair',
		meta: {
			title: 'tms-vehicle-tms-repair',
			icon: '',
			menu_Id: 2171,
			menuName: '维修管理',
      isOpenWindow: false
		}
	},{
	path: 'out-in-trade',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'out-in-trade',
	alwaysShow: true,
	meta: {
		title: 'out-in-trade',
		icon: 'menu-icon el-icon-yrt-pandian',
		menu_Id: 1632,
    menuName: '车辆报表',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=50',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1634',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1634',
			icon: '',
			menu_Id: 1634,
			menuName: '产量统计季分析表',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=17',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1645',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1645',
			icon: '',
			menu_Id: 1645,
			menuName: '出车费用查询',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=22',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1646',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1646',
			icon: '',
			menu_Id: 1646,
			menuName: '收支汇总表',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=34',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1647',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1647',
			icon: '',
			menu_Id: 1647,
			menuName: '油耗量分析',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=40',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1648',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1648',
			icon: '',
			menu_Id: 1648,
			menuName: '维修统计',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=37',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-2182',
		meta: {
			title: 'sys-dev-tools-bi-viewer-2182',
			icon: '',
			menu_Id: 2182,
			menuName: '运费收入月报表',
      isOpenWindow: false
		}
	}]
},{
		path: '/basicInfo/tms/base-driver',
		component: () => import('@/views/basicInfo/tms/base-driver'),
		name: 'basicInfo-tms-base-driver',
		meta: {
			title: 'basicInfo-tms-base-driver',
			icon: '',
			menu_Id: 1627,
			menuName: '司机管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/tms/base-vehicle',
		component: () => import('@/views/basicInfo/tms/base-vehicle'),
		name: 'basicInfo-tms-base-vehicle',
		meta: {
			title: 'basicInfo-tms-base-vehicle',
			icon: '',
			menu_Id: 1629,
			menuName: '车辆管理',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/tms/base-driverVehicle',
		component: () => import('@/views/basicInfo/tms/base-driverVehicle'),
		name: 'basiclnfo-tms-base-driverVehicle',
		meta: {
			title: 'basiclnfo-tms-base-driverVehicle',
			icon: '',
			menu_Id: 1630,
			menuName: '司机车辆绑定',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/map/car-location-map?id=1765&name=司机位置管理',
		component: () => import('@/views/tms/way/map/car-location-map'),
		name: 'tms-way-map-car-location-map',
		meta: {
			title: 'tms-way-map-car-location-map',
			icon: '',
			menu_Id: 1765,
			menuName: '司机位置管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/bill?id=1772&name=任务管理',
		component: () => import('@/views/tms/way/bill'),
		name: 'tms-way-bill',
		meta: {
			title: 'tms-way-bill',
			icon: '',
			menu_Id: 1772,
			menuName: '任务管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/vehicle/tms-preserve',
		component: () => import('@/views/tms/vehicle/tms-preserve'),
		name: 'tms-vehicle-tms-preserve',
		meta: {
			title: 'tms-vehicle-tms-preserve',
			icon: '',
			menu_Id: 2172,
			menuName: '保养登记',
      isOpenWindow: false
		}
	},{
		path: '/tms/vehicle/tms-violation',
		component: () => import('@/views/tms/vehicle/tms-violation'),
		name: 'tms-vehicle-tms-violation',
		meta: {
			title: 'tms-vehicle-tms-violation',
			icon: '',
			menu_Id: 2175,
			menuName: '违章管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/vehicle/tms-routineexpenses',
		component: () => import('@/views/tms/vehicle/tms-routineexpenses'),
		name: 'tms-vehicle-tms-routineexpenses',
		meta: {
			title: 'tms-vehicle-tms-routineexpenses',
			icon: '',
			menu_Id: 2176,
			menuName: '年检管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/vehicle/tms-preservePlan',
		component: () => import('@/views/tms/vehicle/tms-preservePlan'),
		name: 'tms-vehicle-tms-preservePlan',
		meta: {
			title: 'tms-vehicle-tms-preservePlan',
			icon: '',
			menu_Id: 2220,
			menuName: '保养计划',
      isOpenWindow: false
		}
	},{
		path: '/tms/vehicle/tms-insurance',
		component: () => import('@/views/tms/vehicle/tms-insurance'),
		name: 'tms-vehicle-tms-insurance',
		meta: {
			title: 'tms-vehicle-tms-insurance',
			icon: '',
			menu_Id: 2223,
			menuName: '保险管理',
      isOpenWindow: false
		}
	}]
},{
	path: '/cptms',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'cptms',
	alwaysShow: true,
	meta: {
		title: 'cptms',
		icon: 'menu-icon el-icon-yrt-wuliuguanliicon',
		menu_Id: 126,
    menuName: '运输作业管理',
    isOpenWindow: false
	},
	children:[{
	path: 'waybill-base',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'waybill-base',
	alwaysShow: true,
	meta: {
		title: 'waybill-base',
		icon: '',
		menu_Id: 497,
    menuName: '运输作业管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/way/bill',
		component: () => import('@/views/tms/way/bill'),
		name: 'tms-way-bill',
		meta: {
			title: 'tms-way-bill',
			icon: '',
			menu_Id: 499,
			menuName: '运单管理',
      isOpenWindow: false
		}
	},{
		path: '/storage/operation/enter',
		component: () => import('@/views/storage/operation/enter'),
		name: 'storage-operation-enter',
		meta: {
			title: 'storage-operation-enter',
			icon: '',
			menu_Id: 141,
			menuName: '无主货管理',
      isOpenWindow: false
		}
	},{
		path: '/outbound/service/return',
		component: () => import('@/views/outbound/service/return'),
		name: 'outbound-service-return',
		meta: {
			title: 'outbound-service-return',
			icon: '',
			menu_Id: 114,
			menuName: '退货管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/tms/way-bill-exception',
		component: () => import('@/views/tms/tms/way-bill-exception'),
		name: 'tms-tms-way-bill-exception',
		meta: {
			title: 'tms-tms-way-bill-exception',
			icon: '',
			menu_Id: 1730,
			menuName: '异常查询管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/service/claim',
		component: () => import('@/views/tms/service/claim'),
		name: 'tms-service-claim',
		meta: {
			title: 'tms-service-claim',
			icon: '',
			menu_Id: 1731,
			menuName: '保险理赔管理',
      isOpenWindow: false
		}
	}]
},{
	path: 'transport-tms',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'transport-tms',
	alwaysShow: true,
	meta: {
		title: 'transport-tms',
		icon: '',
		menu_Id: 242,
    menuName: '干线运输管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/way/bill-shipment',
		component: () => import('@/views/tms/way/bill-shipment'),
		name: 'tms-way-bill-shipment',
		meta: {
			title: 'tms-way-bill-shipment',
			icon: '',
			menu_Id: 1734,
			menuName: '运输计划方案设计',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/vtms-bill-list-shipment',
		component: () => import('@/views/tms/way/vtms-bill-list-shipment'),
		name: 'tms-way-vtms-bill-list-shipment',
		meta: {
			title: 'tms-way-vtms-bill-list-shipment',
			icon: '',
			menu_Id: 1584,
			menuName: '运输调度管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/bill-arrival',
		component: () => import('@/views/tms/way/bill-arrival'),
		name: 'tms-way-bill-arrival',
		meta: {
			title: 'tms-way-bill-arrival',
			icon: '',
			menu_Id: 1732,
			menuName: '班次交接管理',
      isOpenWindow: false
		}
	}]
},{
	path: 'distribution-biz',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'distribution-biz',
	alwaysShow: true,
	meta: {
		title: 'distribution-biz',
		icon: '',
		menu_Id: 1683,
    menuName: '城配管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/way/bill-distribution',
		component: () => import('@/views/tms/way/bill-distribution'),
		name: 'tms-way-bill-distribution',
		meta: {
			title: 'tms-way-bill-distribution',
			icon: '',
			menu_Id: 230,
			menuName: '运输计划方案设计',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/vtms-bill-list-distribution',
		component: () => import('@/views/tms/way/vtms-bill-list-distribution'),
		name: 'tms-way-vtms-bill-list-distribution',
		meta: {
			title: 'tms-way-vtms-bill-list-distribution',
			icon: '',
			menu_Id: 374,
			menuName: '运输调度管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/vehicle/tms-scheduling',
		component: () => import('@/views/tms/vehicle/tms-scheduling'),
		name: 'tms-vehicle-tms-scheduling',
		meta: {
			title: 'tms-vehicle-tms-scheduling',
			icon: '',
			menu_Id: 2224,
			menuName: '司机排班',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/crm',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'crm',
	alwaysShow: true,
	meta: {
		title: 'crm',
		icon: 'menu-icon el-icon-yrt-ai-connection',
		menu_Id: 294,
    menuName: '运输监控管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/way/routertask',
		component: () => import('@/views/tms/way/routertask'),
		name: 'tms-way-routertask',
		meta: {
			title: 'tms-way-routertask',
			icon: '',
			menu_Id: 446,
			menuName: '车辆在途管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/biz/tms-distribution',
		component: () => import('@/views/tms/biz/tms-distribution'),
		name: 'tms-biz-tms-distribution',
		meta: {
			title: 'tms-biz-tms-distribution',
			icon: '',
			menu_Id: 1802,
			menuName: '订单状态管理（城配）',
      isOpenWindow: false
		}
	},{
		path: '/tms/biz/tms-transport',
		component: () => import('@/views/tms/biz/tms-transport'),
		name: 'tms-biz-tms-transport',
		meta: {
			title: 'tms-biz-tms-transport',
			icon: '',
			menu_Id: 1801,
			menuName: '订单状态管理（干线）',
      isOpenWindow: false
		}
	},{
		path: '/tms/tmsTrack/tms-alarm',
		component: () => import('@/views/tms/tmsTrack/tms-alarm'),
		name: 'tms-tmsTrack-tms-alarm',
		meta: {
			title: 'tms-tmsTrack-tms-alarm',
			icon: '',
			menu_Id: 336,
			menuName: '报警管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/map/car-location-map',
		component: () => import('@/views/tms/way/map/car-location-map'),
		name: 'car-location-map',
		meta: {
			title: 'car-location-map',
			icon: '',
			menu_Id: 1820,
			menuName: 'GPS北斗跟踪管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/map/car-trail-map',
		component: () => import('@/views/tms/way/map/car-trail-map'),
		name: 'car-trail-map',
		meta: {
			title: 'car-trail-map',
			icon: '',
			menu_Id: 1821,
			menuName: '轨迹回放',
      isOpenWindow: false
		}
	},{
		path: '/tms/way/car-trail',
		component: () => import('@/views/tms/way/car-trail'),
		name: 'car-trail',
		meta: {
			title: 'car-trail',
			icon: '',
			menu_Id: 1826,
			menuName: '轨迹列表',
      isOpenWindow: false
		}
	}]
},{
	path: '/outbound',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'outbound',
	alwaysShow: true,
	meta: {
		title: 'outbound',
		icon: 'menu-icon el-icon-yrt-chuku1',
		menu_Id: 110,
    menuName: '清分结算管理',
    isOpenWindow: false
	},
	children:[{
	path: 'operation-tms',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'operation-tms',
	alwaysShow: true,
	meta: {
		title: 'operation-tms',
		icon: '',
		menu_Id: 1835,
    menuName: '预算管理',
    isOpenWindow: false
	},
	children:[{
		path: '/finance/budget/basic',
		component: () => import('@/views/finance/budget/basic'),
		name: 'finance-budget-basic',
		meta: {
			title: 'finance-budget-basic',
			icon: 'menu-icon el-icon-yrt-tongji',
			menu_Id: 2328,
			menuName: '行政预算管理',
      isOpenWindow: false
		}
	},{
		path: '/tms/biz/tms-distribution-budget',
		component: () => import('@/views/tms/biz/tms-distribution-budget'),
		name: 'tms-biz-tms-distribution-budget',
		meta: {
			title: 'tms-biz-tms-distribution-budget',
			icon: 'menu-icon el-icon-yrt-tongji',
			menu_Id: 2323,
			menuName: '运输成本预算',
      isOpenWindow: false
		}
	},{
		path: '/tms/biz/tms-vehicle-budget',
		component: () => import('@/views/tms/biz/tms-vehicle-budget'),
		name: 'tms-biz-tms-vehicle-budget',
		meta: {
			title: 'tms-biz-tms-vehicle-budget',
			icon: 'menu-icon el-icon-yrt-tongji',
			menu_Id: 2327,
			menuName: '车辆预算管理',
      isOpenWindow: false
		}
	},{
	path: '/m-491',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'm-491',
	alwaysShow: true,
	meta: {
		title: 'm-491',
		icon: '',
		menu_Id: 491,
    menuName: '预算报表',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=86&name=运输成本查询',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-2203',
		meta: {
			title: 'sys-dev-tools-bi-viewer-2203',
			icon: '',
			menu_Id: 2203,
			menuName: '运输成本查询',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=83&name=车辆预算查询',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1735',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1735',
			icon: '',
			menu_Id: 1735,
			menuName: '车辆预算查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'm-1643',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'm-1643',
	alwaysShow: true,
	meta: {
		title: 'm-1643',
		icon: '',
		menu_Id: 1643,
    menuName: '预算对比',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=80&name=运输预算对比',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-2204',
		meta: {
			title: 'sys-dev-tools-bi-viewer-2204',
			icon: '',
			menu_Id: 2204,
			menuName: '运输预算对比',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=85&name=车辆预算对比',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-2149',
		meta: {
			title: 'sys-dev-tools-bi-viewer-2149',
			icon: '',
			menu_Id: 2149,
			menuName: '车辆预算对比',
      isOpenWindow: false
		}
	}]
},{
	path: 'm-129',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'm-129',
	alwaysShow: true,
	meta: {
		title: 'm-129',
		icon: '',
		menu_Id: 129,
    menuName: '预算查询',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=87&name=运输成本明细查询',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-134',
		meta: {
			title: 'sys-dev-tools-bi-viewer-134',
			icon: '',
			menu_Id: 134,
			menuName: '运输成本明细查询',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=84&name=车辆预算明细查询',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-469',
		meta: {
			title: 'sys-dev-tools-bi-viewer-469',
			icon: '',
			menu_Id: 469,
			menuName: '车辆预算明细查询',
      isOpenWindow: false
		}
	}]
},{
	path: 'm-1728',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'm-1728',
	alwaysShow: true,
	meta: {
		title: 'm-1728',
		icon: '',
		menu_Id: 1728,
    menuName: '预算图形化展示',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=120&name=运输预算图形展示',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-250',
		meta: {
			title: 'sys-dev-tools-bi-viewer-250',
			icon: '',
			menu_Id: 250,
			menuName: '运输预算图形展示',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=119&name=车辆预算图形展示',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1836',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1836',
			icon: '',
			menu_Id: 1836,
			menuName: '车辆预算图形展示',
      isOpenWindow: false
		}
	}]
}]
},{
	path: 'spare',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'spare-base',
	alwaysShow: true,
	meta: {
		title: 'spare-base',
		icon: '',
		menu_Id: 340,
    menuName: '成本核算',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=91&name=运输资源成本',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-445',
		meta: {
			title: 'sys-dev-tools-bi-viewer-445',
			icon: '',
			menu_Id: 445,
			menuName: '运输资源成本',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=90&name=仓储资源成本',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-330',
		meta: {
			title: 'sys-dev-tools-bi-viewer-330',
			icon: '',
			menu_Id: 330,
			menuName: '仓储资源成本',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=89&name=物流成本核算',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-329',
		meta: {
			title: 'sys-dev-tools-bi-viewer-329',
			icon: '',
			menu_Id: 329,
			menuName: '物流成本核算',
      isOpenWindow: false
		}
	},{
		path: '/finance/budget/costing',
		component: () => import('@/views/finance/budget/costing'),
		name: 'finance-budget-costing',
		meta: {
			title: 'finance-budget-costing',
			icon: '',
			menu_Id: 1596,
			menuName: '成本核算信息录入',
      isOpenWindow: false
		}
	},{
		path: '/finance/budget/provider',
		component: () => import('@/views/finance/budget/provider'),
		name: 'finance-budget-provider',
		meta: {
			title: 'finance-budget-provider',
			icon: '',
			menu_Id: 1597,
			menuName: '供应商成本核算',
      isOpenWindow: false
		}
	}]
},{
	path: 'service-base',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'service-base',
	alwaysShow: true,
	meta: {
		title: 'service-base',
		icon: '',
		menu_Id: 318,
    menuName: '资费管理',
    isOpenWindow: false
	},
	children:[{
		path: '/tms/biz/tms-distribution-account?id=2192&name=资费修改',
		component: () => import('@/views/tms/biz/tms-distribution-account'),
		name: 'tms-biz-tms-distribution-account',
		meta: {
			title: 'tms-biz-tms-distribution-account',
			icon: '',
			menu_Id: 2192,
			menuName: '资费修改',
      isOpenWindow: false
		}
	},{
		path: '/finance/budget/tariffEntry',
		component: () => import('@/views/finance/budget/tariffEntry'),
		name: 'finance-budget-tariffEntry',
		meta: {
			title: 'finance-budget-tariffEntry',
			icon: '',
			menu_Id: 1598,
			menuName: '资费录入',
      isOpenWindow: false
		}
	},{
		path: '/finance/budget/billing',
		component: () => import('@/views/finance/budget/billing'),
		name: 'finance-budget-billing',
		meta: {
			title: 'finance-budget-billing',
			icon: '',
			menu_Id: 1721,
			menuName: '计费管理',
      isOpenWindow: false
		}
	},{
		path: '/finance/budget/settlement',
		component: () => import('@/views/finance/budget/settlement'),
		name: 'finance-budget-settlement',
		meta: {
			title: 'finance-budget-settlement',
			icon: 'menu-icon el-icon-yrt-tongji',
			menu_Id: 2338,
			menuName: '结算管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=93&name=客户结算信息',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-115',
		meta: {
			title: 'sys-dev-tools-bi-viewer-115',
			icon: '',
			menu_Id: 115,
			menuName: '客户结算信息',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=94&name=供应商结算信息',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1581',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1581',
			icon: '',
			menu_Id: 1581,
			menuName: '供应商结算信息',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=95&name=运输结算信息',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1928',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1928',
			icon: '',
			menu_Id: 1928,
			menuName: '运输结算信息',
      isOpenWindow: false
		}
	},{
		path: '/stat/receive/vpurchase-enter-list?id=1929&name=仓储结算信息',
		component: () => import('@/views/stat/receive/vpurchase-enter-list'),
		name: 'stat-receive-vpurchase-enter-list',
		meta: {
			title: 'stat-receive-vpurchase-enter-list',
			icon: '',
			menu_Id: 1929,
			menuName: '仓储结算信息',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=96&name=配送结算信息',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-2184',
		meta: {
			title: 'sys-dev-tools-bi-viewer-2184',
			icon: '',
			menu_Id: 2184,
			menuName: '配送结算信息',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/erp',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'erp',
	alwaysShow: true,
	meta: {
		title: 'erp',
		icon: 'menu-icon el-icon-yrt-cangchuguanli',
		menu_Id: 160,
    menuName: '经营决策管理',
    isOpenWindow: false
	},
	children:[{
	path: '/erp/sale-c',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'erp-sale-c',
	alwaysShow: true,
	meta: {
		title: 'erp-sale-c',
		icon: '',
		menu_Id: 197,
    menuName: '业务分析',
    isOpenWindow: false
	},
	children:[{
		path: '/dashboard/index-trend',
		component: () => import('@/views/dashboard/index-trend'),
		name: 'dashboard-index-trend',
		meta: {
			title: 'dashboard-index-trend',
			icon: 'menu-icon el-icon-yrt-tongji',
			menu_Id: 2345,
			menuName: '综合统计分析',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=102&name=业务量分析',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-198',
		meta: {
			title: 'sys-dev-tools-bi-viewer-198',
			icon: '',
			menu_Id: 198,
			menuName: '业务量分析',
      isOpenWindow: false
		}
	},{
		path: '/finance/budget/structure',
		component: () => import('@/views/finance/budget/structure'),
		name: 'finance-budget-structure',
		meta: {
			title: 'finance-budget-structure',
			icon: 'menu-icon el-icon-yrt-tongji',
			menu_Id: 2329,
			menuName: '成本结构分析',
      isOpenWindow: false
		}
	}]
},{
	path: '/logistics',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'logistics-base',
	alwaysShow: true,
	meta: {
		title: 'logistics-base',
		icon: '',
		menu_Id: 434,
    menuName: '质量分析',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=103&name=运输质量',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-225',
		meta: {
			title: 'sys-dev-tools-bi-viewer-225',
			icon: '',
			menu_Id: 225,
			menuName: '运输质量',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=104&name=仓储质量',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-2178',
		meta: {
			title: 'sys-dev-tools-bi-viewer-2178',
			icon: '',
			menu_Id: 2178,
			menuName: '仓储质量',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=105&name=配送质量',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-436',
		meta: {
			title: 'sys-dev-tools-bi-viewer-436',
			icon: '',
			menu_Id: 436,
			menuName: '配送质量',
      isOpenWindow: false
		}
	},{
		path: '/oa/service/feedback',
		component: () => import('@/views/oa/service/feedback'),
		name: 'oa-service-feedback',
		meta: {
			title: 'oa-service-feedback',
			icon: '',
			menu_Id: 2179,
			menuName: '合作伙伴服务质量反馈',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=106&name=服务反馈报表',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-452',
		meta: {
			title: 'sys-dev-tools-bi-viewer-452',
			icon: '',
			menu_Id: 452,
			menuName: '合作伙伴服务反馈报表',
      isOpenWindow: false
		}
	}]
},{
	path: '/erp/allocate',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'erp-allocate-base',
	alwaysShow: true,
	meta: {
		title: 'erp-allocate-base',
		icon: '',
		menu_Id: 444,
    menuName: '资源分析',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=107&name=综合分析',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-485',
		meta: {
			title: 'sys-dev-tools-bi-viewer-485',
			icon: '',
			menu_Id: 485,
			menuName: '综合分析',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=108&name=物流资源布局图',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1674',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1674',
			icon: '',
			menu_Id: 1674,
			menuName: '物流资源布局图',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=109&name=人力资源分析',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-360',
		meta: {
			title: 'sys-dev-tools-bi-viewer-360',
			icon: '',
			menu_Id: 360,
			menuName: '人力资源分析',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=110&name=车辆资源分析',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-133',
		meta: {
			title: 'sys-dev-tools-bi-viewer-133',
			icon: '',
			menu_Id: 133,
			menuName: '车辆资源分析',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=111&name=仓储资源分析',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-2198',
		meta: {
			title: 'sys-dev-tools-bi-viewer-2198',
			icon: '',
			menu_Id: 2198,
			menuName: '仓储资源分析',
      isOpenWindow: false
		}
	}]
},{
	path: '/erp/service-base',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'erp-service-base',
	alwaysShow: true,
	meta: {
		title: 'erp-service-base',
		icon: '',
		menu_Id: 331,
    menuName: '趋势分析',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=112&name=业务量（收）异常预警',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-2199',
		meta: {
			title: 'sys-dev-tools-bi-viewer-2199',
			icon: '',
			menu_Id: 2199,
			menuName: '业务量（收）异常预警',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=113&name=资源瓶颈预警',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-1715',
		meta: {
			title: 'sys-dev-tools-bi-viewer-1715',
			icon: '',
			menu_Id: 1715,
			menuName: '资源瓶颈预警',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=114&name=运营质量预警',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-292',
		meta: {
			title: 'sys-dev-tools-bi-viewer-292',
			icon: '',
			menu_Id: 292,
			menuName: '运营质量预警',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/bi-viewer?isviewer=true&id=16&name=趋势分析图表',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-16',
		meta: {
			title: 'sys-dev-tools-bi-viewer-16',
			icon: '',
			menu_Id: 396,
			menuName: '趋势分析图表',
      isOpenWindow: false
		}
	}]
}]
},{
	path: '/sys',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'sys',
	alwaysShow: true,
	meta: {
		title: 'sys',
		icon: 'menu-icon el-icon-yrt-shezhi',
		menu_Id: 1,
    menuName: '系统管理',
    isOpenWindow: false
	},
	children:[{
	path: '/sys/sys-config',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'sys-sys-config',
	alwaysShow: true,
	meta: {
		title: 'sys-sys-config',
		icon: '',
		menu_Id: 2201,
    menuName: '系统配置',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/base/settings',
		component: () => import('@/views/sys/basic/settings'),
		name: 'sys-basic-settings',
		meta: {
			title: 'sys-basic-settings',
			icon: '',
			menu_Id: 245,
			menuName: '系统参数设置',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/base/task-config',
		component: () => import('@/views/basicInfo/base/task-config'),
		name: 'basicInfo-base-task-config',
		meta: {
			title: 'basicInfo-base-task-config',
			icon: '',
			menu_Id: 2147,
			menuName: '任务配置表',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/base/task-push',
		component: () => import('@/views/basicInfo/base/task-push'),
		name: 'basicInfo-base-task-push',
		meta: {
			title: 'basicInfo-base-task-push',
			icon: '',
			menu_Id: 2193,
			menuName: '任务推送',
      isOpenWindow: false
		}
	},{
		path: '/basicInfo/base/task-log',
		component: () => import('@/views/basicInfo/base/task-log'),
		name: 'basicInfo-base-task-log',
		meta: {
			title: 'basicInfo-base-task-log',
			icon: '',
			menu_Id: 2177,
			menuName: '任务推送日志',
      isOpenWindow: false
		}
	}]
},{
		path: '/sys/dev-tools/bi-viewer',
		component: () => import('@/views/sys/dev-tools/bi-designer/viewer'),
		name: 'sys-dev-tools-bi-viewer-353',
		meta: {
			title: 'sys-dev-tools-bi-viewer-353',
			icon: '',
			menu_Id: 353,
			menuName: 'BI报表',
      isOpenWindow: false
		}
	},{
	path: 'permission',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'permission',
	alwaysShow: true,
	meta: {
		title: 'permission',
		icon: '',
		menu_Id: 2,
    menuName: '用户及权限',
    isOpenWindow: false
	},
	children:[{
		path: '/sys/permission/user',
		component: () => import('@/views/sys/permission/user'),
		name: 'sys-permission-user',
		meta: {
			title: 'sys-permission-user',
			icon: '',
			menu_Id: 4,
			menuName: '用户管理',
      isOpenWindow: false
		}
	},{
		path: 'dept',
		component: () => import('@/views/sys/permission/dept'),
		name: 'sys-permission-dept',
		meta: {
			title: 'sys-permission-dept',
			icon: '',
			menu_Id: 285,
			menuName: '部门管理',
      isOpenWindow: false
		}
	},{
		path: '/sys/permission/role',
		component: () => import('@/views/sys/permission/role'),
		name: 'sys-permission-role',
		meta: {
			title: 'sys-permission-role',
			icon: '',
			menu_Id: 18,
			menuName: '角色管理',
      isOpenWindow: false
		}
	},{
		path: 'role-auth',
		component: () => import('@/views/sys/permission/role-auth'),
		name: 'sys-permission-role-auth',
		meta: {
			title: 'sys-permission-role-auth',
			icon: '',
			menu_Id: 5,
			menuName: '角色权限设置',
      isOpenWindow: false
		}
	}]
},{
	path: 'dev-tools',
	component: () => import("@/layout/layout-base"),
	redirect: '/',
	name: 'dev-tools',
	alwaysShow: true,
	meta: {
		title: 'dev-tools',
		icon: '',
		menu_Id: 3,
    menuName: '开发工具',
    isOpenWindow: false
	},
	children:[{
		path: 'ui-designer',
		component: () => import('@/views/sys/dev-tools/ui-designer'),
		name: 'sys-dev-tools-ui-designer',
		meta: {
			title: 'sys-dev-tools-ui-designer',
			icon: '',
			menu_Id: 486,
			menuName: 'VueUI设计器',
      isOpenWindow: false
		}
	},{
		path: 'print-designer',
		component: () => import('@/views/sys/dev-tools/print-designer'),
		name: 'sys-dev-tools-print-designer',
		meta: {
			title: 'sys-dev-tools-print-designer',
			icon: '',
			menu_Id: 490,
			menuName: 'Vue打印设计器',
      isOpenWindow: false
		}
	},{
		path: 'flow-designer',
		component: () => import('@/views/sys/dev-tools/flow-designer'),
		name: 'sys-dev-tools-flow-designer',
		meta: {
			title: 'sys-dev-tools-flow-designer',
			icon: '',
			menu_Id: 1576,
			menuName: '流程设计器',
      isOpenWindow: false
		}
	},{
		path: 'bi-designer',
		component: () => import('@/views/sys/dev-tools/bi-designer'),
		name: 'sys-dev-tools-bi-designer',
		meta: {
			title: 'sys-dev-tools-bi-designer',
			icon: '',
			menu_Id: 1577,
			menuName: '报表设计器',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/mvctableinfo',
		component: () => import('@/views/sys/dev/mvctableinfo'),
		name: 'sys-dev-mvctableinfo',
		meta: {
			title: 'sys-dev-mvctableinfo',
			icon: '',
			menu_Id: 6,
			menuName: 'Mvc开发中心',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev-tools/ui-designer-user',
		component: () => import('@/views/sys/dev-tools/ui-designer'),
		name: 'sys-dev-tools-ui-designer-user',
		meta: {
			title: 'sys-dev-tools-ui-designer-user',
			icon: '',
			menu_Id: 186,
			menuName: 'UI设计',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/menu',
		component: () => import('@/views/sys/dev/menu'),
		name: 'sys-dev-menu',
		meta: {
			title: 'sys-dev-menu',
			icon: '',
			menu_Id: 496,
			menuName: 'Vue菜单设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/menu-app',
		component: () => import('@/views/sys/dev/menu-app'),
		name: 'sys-dev-menu-app',
		meta: {
			title: 'sys-dev-menu-app',
			icon: '',
			menu_Id: 1723,
			menuName: 'APP菜单设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/import',
		component: () => import('@/views/sys/dev/import'),
		name: 'sys-dev-import',
		meta: {
			title: 'sys-dev-import',
			icon: '',
			menu_Id: 319,
			menuName: '导入设置',
      isOpenWindow: false
		}
	},{
		path: 'export-setting',
		component: () => import('@/views/sys/basic/export-setting'),
		name: 'sys-basic-export-setting',
		meta: {
			title: 'sys-basic-export-setting',
			icon: '',
			menu_Id: 384,
			menuName: '导出设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/dropdown',
		component: () => import('@/views/sys/dev/dropdown'),
		name: 'sys-dev-dropdown',
		meta: {
			title: 'sys-dev-dropdown',
			icon: '',
			menu_Id: 10,
			menuName: '下拉框设置',
      isOpenWindow: false
		}
	},{
		path: '/sys/dev/param-type',
		component: () => import('@/views/sys/dev/param-type'),
		name: 'sys-dev-param-type',
		meta: {
			title: 'sys-dev-param-type',
			icon: '',
			menu_Id: 11,
			menuName: '下拉框值设置',
      isOpenWindow: false
		}
	}]
}]
}]
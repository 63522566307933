<template>
  <router-view />
</template>

<script>
export default {
  name: "layout-base",
  computed: {
    cachedViews () {
      return this.$store.state.tagsView.cachedViews;
    },
    key () {
      return this.$route.fullPath;
    }
  }
};
</script>

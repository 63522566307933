<template>
  <el-dropdown trigger="click" class="international">
    <div @click="clockIn">
      <i class="el-icon-s-claim international-icon" />
      打卡
    </div>

  </el-dropdown>
</template>

<script>
export default {
  data () {
    return {};
  },
  methods: {
    // 用户打卡
    clockIn () {
      const url = "/api/tms/oaScheduling/clockIn";
      const params = {};
      this.common.ajax(
        url,
        params,
        res => {
          this.common.showMsg(res);
        },
        true
      );
    }
  }
};
</script>

<style scoped>
.international-icon {
  font-size: 27px;
  cursor: pointer;
  height: 20px;
  vertical-align: -5px !important;
}
.international {
  vertical-align: -12px !important;
  cursor: pointer;
}
</style>


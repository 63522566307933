const TokenKey = "Zht-Admin-Token"; // cookies key

const inFifteenMinutes = new Date(new Date().getTime() + 120 * 60 * 1000); // expires time
// const inFifteenMinutes = new Date(new Date().getTime() + 10* 1000)  // expires time

export const getToken = () => localStorage.getItem(TokenKey);

export const setToken = token => localStorage.setItem(TokenKey, token, { expires: inFifteenMinutes });
// export const setToken = token => localStorage.setItem(TokenKey, token)

export const removeToken = () => localStorage.removeItem(TokenKey);

import store from "@/store";
const userInfoKey = "cookie_userinfo";
const menuListKey = "cookie_menuList";
const menuCustomListKey = "cookie_menuCustomList";

export class userInfoCookie {
  static setUserInfo (userInfo) {
    var key = userInfoKey + "_" + store.getters.userType;
    return localStorage.setItem(key, JSON.stringify(userInfo));
  }
  static getUserInfo () {
    var key = userInfoKey + "_" + store.getters.userType;
    var _userInfo = localStorage.getItem(key);
    if (!_userInfo) return null;

    try {
      _userInfo = JSON.parse(_userInfo);
      return _userInfo;
    } catch (error) {
      return null;
    }
  }
  static removeUserInfo () {
    var key = userInfoKey + "_" + store.getters.userType;
    return localStorage.removeItem(key);
  }
}

export class menuListCookie {
  static setMenuList (_menuList) {
    var key = menuListKey + "_" + store.getters.userType;
    let m = "{}";
    if (_menuList) {
      m = JSON.stringify(_menuList);
    }
    return localStorage.setItem(key, m);
  }
  static getMenuList () {
    try {
      var key = menuListKey + "_" + store.getters.userType;
      var _menuList = localStorage.getItem(key);
      if (!_menuList) return null;
      _menuList = JSON.parse(_menuList);

      return _menuList;
    } catch (error) {
      return null;
    }
  }
  static removeMenuList () {
    var key = menuListKey + "_" + store.getters.userType;
    return localStorage.removeItem(key);
  }

  // 自定义菜单
  static setCustomMenuList (_menuList) {
    var key = menuCustomListKey + "_" + store.getters.userType;
    let m = "{}";
    if (_menuList) {
      m = _menuList;
    }
    return localStorage.setItem(key, m);
  }
  static getCustomMenuList () {
    try {
      var key = menuCustomListKey + "_" + store.getters.userType;
      var _menuList = localStorage.getItem(key);
      if (!_menuList) return null;
      // _menuList = JSON.parse(_menuList);

      return _menuList;
    } catch (error) {
      return null;
    }
  }
  static removeCustomMenuList () {
    var key = menuCustomListKey + "_" + store.getters.userType;
    return localStorage.removeItem(key);
  }
}

<template>
  <div>
    <div class="font-14 warning-box" @click="showDrawer">
      <i class="el-icon-s-opportunity international-icon" />
      <span>预警</span>
    </div>
    <el-drawer title="预警弹窗" :visible.sync="drawer" :size="550" direction="rtl" append-to-body>
      <el-card class="box-card">
        <div slot="header" class="clearfix">业务量（收）异常预警</div>
        <el-table :data="gridData1" size="mini">
          <el-table-column property="date" label="日期" width="100">
            <template slot-scope="{ row, col }">
              <el-link type="primary" @click="link('/sys/dev-tools/bi-viewer?isviewer=true&id=112&name=业务量（收）异常预警')">{{ row.date }}</el-link>
            </template>
          </el-table-column>
          <el-table-column property="name" label="仓库" width="120"></el-table-column>
          <el-table-column property="address" label="预警类型"></el-table-column>
        </el-table>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="clearfix">资源瓶颈预警</div>
        <el-table :data="gridData1" size="mini">
          <el-table-column property="date" label="日期" width="100">
            <template slot-scope="{ row, col }">
              <el-link type="primary" @click="link('/sys/dev-tools/bi-viewer?isviewer=true&id=113&name=资源瓶颈预警')">{{ row.date }}</el-link>
            </template>
          </el-table-column>
          <el-table-column property="name" label="仓库" width="120"></el-table-column>
          <el-table-column property="address" label="预警类型"></el-table-column>
        </el-table>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="clearfix">运营质量预警</div>
        <el-table :data="gridData1" size="mini">
          <el-table-column property="date" label="日期" width="100">
            <template slot-scope="{ row, col }">
              <el-link type="primary" @click="link('/sys/dev-tools/bi-viewer?isviewer=true&id=114&name=运营质量预警')">{{ row.date }}</el-link>
            </template>
          </el-table-column>
          <el-table-column property="name" label="仓库" width="120"></el-table-column>
          <el-table-column property="address" label="预警类型"></el-table-column>
        </el-table>
      </el-card>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      drawer: false,
      gridData1: [
        {
          date: "2023-10-02",
          name: "北京仓",
          address: "运输预警、入库预警"
        },
        {
          date: "2023-10-04",
          name: "北京仓",
          address: "运输预警、入库预警、出库预警"
        },
        {
          date: "2023-11-01",
          name: "上海仓",
          address: "运输预警"
        },
        {
          date: "2023-11-03",
          name: "上海仓",
          address: "运输预警、入库预警、出库预警"
        }
      ]
    };
  },
  methods: {
    // 显示drawer
    showDrawer () {
      this.drawer = true;
    },
    link (url) {
      try {
        this.$router.push({ path: url });
      } catch (error) {
        console.log(error);
      }
      this.drawer = false;
    },
    getGridData () {
      const url = "/api/stat/biz/warning/getGridData";
      const params = {};
      this.common.ajax(url, params, res => {
        if (res.result) {
          this.gridData1 = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
.warning-box {
  color: #5a5e66;
  cursor: pointer;
}
.international-icon {
  font-size: 20px;
  cursor: pointer;
  height: 20px;
  vertical-align: -5px !important;
}
.international {
  vertical-align: -12px !important;
  cursor: pointer;
}
.box-card {
  margin: 10px;
}
</style>
